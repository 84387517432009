// videoTexture.js
var VideoTexture = pc.createScript('videoTexture');

VideoTexture.attributes.add('video', {
    title: 'Video',
    description: 'MP4 video asset to play back on this video texture.',
    type: 'asset'
});

VideoTexture.attributes.add('videoMaterial', {
    title: 'Video Material',
    type: 'asset',
    assetType: 'material'
});

VideoTexture.attributes.add('ImageElement', {
    title: 'Image Element',
    type: 'entity'
});

VideoTexture.attributes.add('loop', {
    title: 'Loop',
    type: 'boolean',
    default: false
});

VideoTexture.attributes.add('videoURL', {
    title: 'Video URL',
    type: 'string',
});

VideoTexture.attributes.add('autoPlay', {
    title: 'autoPlay (low power mode iOS wont work)',
    type: 'boolean',
    default: true,
});

VideoTexture.attributes.add('playEvent', {
    title: 'Play Event',
    type: 'string',
});

// initialize code called once per entity
VideoTexture.prototype.initialize = function() {
    this.readyToPlay = false;

    if (this.playEvent)
    {
        this.app.on(this.playEvent, this.play, this);
    }

    VideoTexture.instance = this;

    var app = this.app;

    // Create HTML Video Element to play the video
    this.videoElement = document.createElement('video');
    //this.videoElement.loop = true;
 
    // muted attribute is required for videos to autoplay
    this.videoElement.muted = true;

    // critical for iOS or the video won't initially play, and will go fullscreen when playing
    this.videoElement.playsInline = true;

    this.videoElement.loop = this.loop;

    // needed because the video is being hosted on a different server url
    this.videoElement.crossOrigin = "anonymous";

    // iOS video texture playback requires that you add the video to the DOMParser
    // with at least 1x1 as the video's dimensions
    var style = this.videoElement.style;
    style.width = '1px';
    style.height = '1px';
    style.position = 'absolute';
    style.opacity = '0';
    style.zIndex = '-1000';
    style.pointerEvents = 'none';

    document.body.appendChild(this.videoElement);

    // Create a texture to hold the video frame data
    this.videoTexture = new pc.Texture(app.graphicsDevice, {
        format: pc.PIXELFORMAT_R8_G8_B8,
        addressU: pc.ADDRESS_CLAMP_TO_EDGE,
        addressV: pc.ADDRESS_CLAMP_TO_EDGE,
        mipmaps: false
    });
    this.videoTexture.setSource(this.videoElement);

    this.videoElement.addEventListener('canplaythrough', function (e) {
        if (this.ImageElement)
        {
            if (this.ImageElement.element)
            {
                this.ImageElement.element.texture = this.videoTexture;
                this.ImageElement.element.opacity = 1;
                if (this.autoPlay) 
                    this.videoElement.play().then(() => {}).catch(this.playError.bind(this));
                else
                    this.readyToPlay = true;
            }
        }
        else if (this.videoMaterial)
        {
            var material = this.videoMaterial.resource;
            material.emissiveMap = this.videoTexture;
            material.opacityMap = this.videoTexture;
            material.update();
        }
        if (this.autoPlay) 
            this.videoElement.play().then(() => {}).catch(this.playError.bind(this));
        else
            this.readyToPlay = true;
    }.bind(this));

    //set video source
    if (this.videoURL != "")
    {
        this.videoElement.src = this.videoURL;
    }
    else
    {
        this.videoElement.src = this.video ? this.video.getFileUrl() : this.videoUrl;
    }
    

    document.body.appendChild(this.videoElement);
    this.videoElement.load();
};

VideoTexture.prototype.playError = function() {
    //alert("Can't play");
    //this.ImageElement.enabled = false;
    //this.CantPlayElement.enabled = true;
};

// update code called every frame
VideoTexture.prototype.update = function(dt) {
    // Transfer the latest video frame to the video texture
    this.videoTexture.upload();
};

VideoTexture.prototype.play = function() {
    if (this.readyToPlay)
    {
        this.videoElement.play().then(() => {}).catch(this.playError.bind(this));
    }
};

