// matchCamAspect.js
var MatchCamAspect = pc.createScript('matchCamAspect');

// initialize code called once per entity
MatchCamAspect.prototype.initialize = function() {

};

// update code called every frame
MatchCamAspect.prototype.update = function(dt) {

};

// swap method called for script hot-reloading
// inherit your script state here
// MatchCamAspect.prototype.swap = function(old) { };

// to learn more about script anatomy, please read:
// https://developer.playcanvas.com/en/user-manual/scripting/

