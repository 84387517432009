// device.js
class Device {
	static requestAccelerometer() {
		if (!Device.accelerometer) {
			if (typeof DeviceMotionEvent['requestPermission'] === 'function') {
				DeviceMotionEvent.requestPermission().then(response => {
					if (response == 'granted') {
						Device.accelerometer = true;
						window.addEventListener('devicemotion', (e) => {
							Device.devicemotion = e;
						});
					}
					if (response == 'denied') {
						Device.accelerometer = false;
					}
				}).catch(console.error);
			} else {
				window.addEventListener('devicemotion', (e) => {
					Device.devicemotion = e;
				});
				Device.accelerometer = true;
			}
		}
	}
	static requestGyro() {
		if (!Device.gyro) {
			if (typeof DeviceOrientationEvent['requestPermission'] === 'function') {
				DeviceOrientationEvent.requestPermission().then(response => {
					if (response == 'granted') {
						Device.gyro = true;
						window.addEventListener('deviceorientation', (e) => {
							Device.deviceorientation = e;
						});
			            window.addEventListener( 'orientationchange', (e) => {
			                Device.screenOrientation = window.orientation;
			            });
					}
					if (response == 'denied') {
						Device.gyro = false;
					}
				}).catch(console.error);
			} else {
				window.addEventListener('deviceorientation', (e) => {
					Device.deviceorientation = e;
				});
                window.addEventListener( 'orientationchange', (e) => {
                    Device.screenOrientation = window.orientation;
                });
				Device.gyro = true;
			}
		}
	}
	static requestMicrophone() {}

	static requestLocation() {
		if (!navigator.geolocation) {
			onFail('Geolocation is not supported by your browser');
		} else {
			navigator.geolocation.getCurrentPosition(Device.requestLocation_success, Device.requestLocation_error);
		}
	}
	static requestLocation_success(position) {
		Device.geolocation = position;
	}
	static requestLocation_error(error) {
		console.error(error);
		Device.geolocation = null;
	}
	static getOrientation() {
		var orientation = null;
		if (window.matchMedia("(orientation: portrait)").matches) {
			orientation = 'portrait';
		}
		if (window.matchMedia("(orientation: landscape)").matches) {
			orientation = 'landscape';
		}
		return orientation;
	}
}

window.Device = Device;

