// screenshot.js
var Screenshot = pc.createScript('screenshot');

Screenshot.attributes.add('Entities', 
{
    type: 'json',
    schema: 
    [
        {
            name: 'screenshotCam',
            title: 'Screenshot Camera',
            type: 'entity',
        },
        {
            name: 'capturedImage',
            title: 'Captured Image',
            type: 'entity',
        },
        {
            name: 'closeButton',
            title: 'Close Button',
            type: 'entity',
        },
        {
            name: 'captureButton',
            title: 'Capture Button',
            type: 'entity',
        },
        {
            name: 'shareButton',
            title: 'Share Button',
            type: 'entity',
        },
        {
            name: 'flashEnt',
            title: 'Flash Entity',
            type: 'entity',
        },
        {
            name: 'statsEnt',
            title: 'Stats Entity',
            type: 'entity',
        },
        {
            name: 'captureEntities',
            title: 'Capture Screen Entities',
            type: 'entity',
            array: true,
        },
        {
            name: 'screenshotEntities',
            title: 'Screenshot Screen Entities',
            type: 'entity',
            array: true,
        }
   ],
});

Screenshot.attributes.add('Settings', 
{
    type: 'json',
    schema: 
    [
        {
            name: 'closeButtonTexture',
            title: 'Close Button Texture',
            description: 'Fallback close button texture for sharing on IOS.',
            type: 'asset',
            assetType: 'texture'
        }
    ],
});

Screenshot.prototype.initialize = function () {
    this.createNewRenderTexture();
    
    this.app.graphicsDevice.on('resizecanvas', function(w, h) {
        this.secsSinceSameSize = 0; 
    }, this);
    
    var device = this.app.graphicsDevice;
    this.lastWidth = device.width;
    this.lastHeight = device.height;
    
    this.secsSinceSameSize = 0;
    
    this.triggerScreenshot = false;
    
    var onTakeScreenshot = function () {
        this.triggerScreenshot = true;
        this.Entities.screenshotCam.enabled = true;   
    };
    
    this.app.on('ui:takeScreenshot', onTakeScreenshot, this);
    this.app.on('postrender', this.postRender, this);
    this.app.on('Share', shareCanvas, this);
    if(this.Entities.closeButton){
        this.Entities.closeButton.button.on('click', this.hideShare, this);
    }
    if(this.Entities.captureButton){
        this.Entities.captureButton.button.on('click', this.clickCapture, this);
    }
    
    // Disable the screenshot camera as we only want it enabled when we take the screenshot itself
    this.Entities.screenshotCam.enabled = false;
    
    // Ensure it gets rendered first so not to interfere with other cameras
     this.Entities.screenshotCam.camera.priority = -1;
    
    // Add a <a> to use to download an image file
    var linkElement = document.createElement('a');
    linkElement.id = 'link';
    window.document.body.appendChild(linkElement);
        
    // Clean up resources if script is destroyed
    this.on('destroy', function() {
        this.app.off('ui:takeScreenshot', onTakeScreenshot, this);
        this.app.off('postrender', this.postRender, this);
        
        window.document.body.removeChild(linkElement);
        
        if (this.renderTarget) {
            this.renderTarget.destroy();
            this.renderTarget = null;
        }
        
        if (this.colorTexture) {
            this.colorTexture.destroy();
            this.colorTexture = null;
        }
        
        if (this.depthTexture) {
            this.depthTexture.destroy();
            this.depthTexture = null;
        }
        
        this.canvas = null;
        this.context = null;
        
    }, this);
};

Screenshot.prototype.clickCapture = function(){
    
    if(this.Entities.flashEnt)
    {
        this.Entities.captureButton.enabled = false;
        if(this.Entities.captureEntities.length>0){
            for(var j = 0; j < this.Entities.captureEntities.length; j++)
            {
                this.Entities.captureEntities[j].enabled = false;
            }
        }
        this.app.fire("ui:takeScreenshot");
        this.Entities.flashEnt.enabled = true;
    }
};

Screenshot.prototype.hideShare = function()
{
    if(this.Entities.flashEnt){
        this.Entities.flashEnt.enabled = false;
    }
    if(this.Entities.shareButton){
        this.Entities.shareButton.enabled = false;
    }
    if(this.Entities.screenshotEntities.length>0){
        for(var i = 0; i < this.Entities.screenshotEntities.length; i++)
        {
            this.Entities.screenshotEntities[i].enabled = false;
        }
    }

    if(this.Entities.captureEntities.length>0){
        for(var j = 0; j < this.Entities.captureEntities.length; j++)
        {
            this.Entities.captureEntities[j].enabled = true;
        }
    }
    this.Entities.statsEnt.enabled = true;
};


// update code called every frame
Screenshot.prototype.update = function(dt) {
    // We don't want to be constantly creating an new texture if the window is constantly
    // changing size (e.g a user that is dragging the corner of the browser over a period)
    // of time. 
    
    // We wait for the the canvas width and height to stay the same for short period of time
    // before creating a new texture to render against.
    
    var device = this.app.graphicsDevice;
    
    if (device.width == this.lastWidth && device.height == this.lastHeight) {
        this.secsSinceSameSize += dt;
    }
    
    if (this.secsSinceSameSize > 0.25) {
        if (this.unScaledTextureWidth != device.width || this.unScaledTextureHeight != device.height) {
            this.createNewRenderTexture();
        } 
    } 
    
    this.lastWidth = device.width;
    this.lastHeight = device.height;
};


Screenshot.prototype.postRender = function () {
    if (this.triggerScreenshot) {
        this.takeScreenshot('snap');   
        this.triggerScreenshot = false;
        this.Entities.screenshotCam.enabled = false;

    }
};


Screenshot.prototype.createNewRenderTexture = function() {
    var device = this.app.graphicsDevice;

    // Make sure we clean up the old textures first and remove 
    // any references
    if (this.colorTexture && this.depthTexture && this.renderTarget) {
        var oldRenderTarget = this.renderTarget;
        var oldColorTexture = this.colorTexture;
        var oldDepthTexture = this.depthTexture;
        
        this.renderTarget = null;
        this.colorTexture = null;
        this.depthTexture = null;
        
        oldRenderTarget.destroy();
        oldColorTexture.destroy();
        oldDepthTexture.destroy();
    }
        
    // Create a new texture based on the current width and height
    var colorBuffer = new pc.Texture(device, {
        width: device.width,
        height: device.height,
        format: pc.PIXELFORMAT_R8_G8_B8,
        autoMipmap: true
    });
    
    var depthBuffer = new pc.Texture(device, {
        format: pc.PIXELFORMAT_DEPTHSTENCIL,
        width: device.width,
        height: device.height,
        mipmaps: false,
        addressU: pc.ADDRESS_CLAMP_TO_EDGE,
        addressV: pc.ADDRESS_CLAMP_TO_EDGE
    });

    colorBuffer.minFilter = pc.FILTER_LINEAR;
    colorBuffer.magFilter = pc.FILTER_LINEAR;
    var renderTarget = new pc.RenderTarget({
        colorBuffer: colorBuffer,
        depthBuffer: depthBuffer,
        samples: 4 // Enable anti-alias 
    });
    
     this.Entities.screenshotCam.camera.renderTarget = renderTarget;
    
    this.unScaledTextureWidth = device.width;
    this.unScaledTextureHeight = device.height;
    
    this.colorTexture = colorBuffer;
    this.depthTexture = depthBuffer;
    this.renderTarget = renderTarget;
    
    var cb = this.renderTarget.colorBuffer;
    
    if (!this.canvas) {
        // Create a canvas context to render the screenshot to
        this.canvas = window.document.createElement('canvas');
        this.context = this.canvas.getContext('2d');
    }

    this.canvas.width = cb.width;
    this.canvas.height = cb.height;

    // The render is upside down and back to front so we need to correct it
    this.context.globalCompositeOperation = "copy";
    this.context.setTransform(1, 0, 0, 1, 0, 0);
    this.context.scale(1,-1);
    this.context.translate(0,-this.canvas.height);
    
    this.pixels = new Uint8Array(colorBuffer.width * colorBuffer.height * 4);
};


// From https://forum.playcanvas.com/t/save-specific-rendered-entities-to-image/2855/4
Screenshot.prototype.takeScreenshot = function (filename) {

    if(this.Entities.screenshotEntities.length>0){
        for(var i = 0; i < this.Entities.screenshotEntities.length; i++)
        {
                this.Entities.screenshotEntities[i].enabled = true;
        }
    }
    var colorBuffer = this.renderTarget.colorBuffer;
    var depthBuffer = this.renderTarget.depthBuffer;
    
    // Fix for WebKit: https://github.com/playcanvas/developer.playcanvas.com/issues/268
    // context must be cleared otherwise the first screenshot is always used
    
    // https://stackoverflow.com/a/6722031/8648403
    // Store the current transformation matrix
    this.context.save();

    // Use the identity matrix while clearing the canvas
    this.context.setTransform(1, 0, 0, 1, 0, 0);
    this.context.clearRect(0, 0, colorBuffer.width, colorBuffer.height);

    // Restore the transform
    this.context.restore();

    var gl = this.app.graphicsDevice.gl;
    var fb = this.app.graphicsDevice.gl.createFramebuffer();
    var pixels = this.pixels;
    
    gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, colorBuffer._glTexture, 0);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_STENCIL_ATTACHMENT, gl.TEXTURE_2D, depthBuffer._glTexture, 0);
    gl.readPixels(0, 0, colorBuffer.width, colorBuffer.height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);
    
    gl.deleteFramebuffer(fb);

    // first, create a new ImageData to contain our pixels
    var imgData = this.context.createImageData(colorBuffer.width, colorBuffer.height); // width x height
    var data = imgData.data;
    
    // Get a pointer to the current location in the image.
    var palette = this.context.getImageData(0, 0, colorBuffer.width, colorBuffer.height); //x,y,w,h

    // Wrap your array as a Uint8ClampedArray
    palette.data.set(new Uint8ClampedArray(pixels)); // assuming values 0..255, RGBA, pre-mult.

    // Repost the data.
    this.context.putImageData(palette, 0, 0);
    this.context.drawImage(this.canvas, 0,0);
    
    
    //this.selfieImage = this.canvas.toDataURL('image/png');
    this.selfieImage = this.canvas.toDataURL();

    let tex = new pc.Texture( this.app.graphicsDevice, {
            mipmaps: false
        } );

        tex.minFilter = pc.FILTER_LINEAR;
        tex.magFilter = pc.FILTER_LINEAR;
        tex.addressU = pc.ADDRESS_CLAMP_TO_EDGE;
        tex.addressV = pc.ADDRESS_CLAMP_TO_EDGE;

        let img = document.createElement( 'img' );
        img.src = this.selfieImage;
        img.crossOrigin = 'anonymous';
        img.onload = ( e ) => {
            tex.setSource( img );
            this.Entities.capturedImage.element.width = tex.width;
            this.Entities.capturedImage.element.height = tex.height;
            this.Entities.capturedImage.element.texture = tex;
        };
    //this.capturedImage.element.texture = this.selfieImage;

    this.app.fire("shareReady");
    this.Entities.statsEnt.enabled = false;
};

async function shareCanvas() {
    
    const dataUrl = this.selfieImage;
    const blob = await fetch(dataUrl).then(r => r.blob());
    const filesArray = [
        new File(
        [blob],
        'selfie.png',
        {
        type: blob.type,
        lastModified: new Date().getTime()
        }
        )
    ];

    if (navigator.canShare && navigator.canShare({ files: filesArray })) {
        shareFiles(filesArray, this);
    }else{
        canvas.style.zIndex =1;

        //Create Div
        var fullScreenDiv = document.createElement('div');
        fullScreenDiv.style.width = '100%';
        fullScreenDiv.style.height = '100%';
        fullScreenDiv.style.zIndex = 2;
        fullScreenDiv.style.webkitUserSelect = 'none';
        fullScreenDiv.position = 'absolute';
        fullScreenDiv.id = 'ShareScreen';
        document.body.prepend(fullScreenDiv);

        console.log("clicked share");
        var image = document.createElement('img');
        image.src = this.selfieImage;
        var style = image.style;
        style.width = '100%';
        style.height = '100%';
        style.position = 'absolute';
        style.zIndex = 2;
        fullScreenDiv.appendChild(image);

        var h1 = document.createElement("H1");                
        var t = document.createTextNode("Press and hold to share."); 
        h1.appendChild(t);
        var style2 = h1.style;
        style2.width = '100%';
        style2.height = '100px';
        style2.position = 'absolute';
        style2.color = 'white';
        style2.marginLeft = 'auto';
        style2.marginRight = 'auto';
        style2.zIndex = 3;
        style2.top = '70%';
        style2.bottom = '30%';
        style2.textAlign = 'center';
        style2.fontSize = '20px';
        style2.textShadow = "2px 2px #000000";
        style2.userSelect = 'none';
        style2.webkitUserSelect = 'none';
        fullScreenDiv.appendChild(h1);

        var button = document.createElement('input');
        button.type = 'image';
        button.src = this.Settings.closeButtonTexture.getFileUrl();
        button.innerHTML = 'Back';
        button.className = 'btn-styled';
        button.style.zIndex = 3;
        button.style.position ='absolute';
        button.style.width = '50px';
        button.style.height = '50px';
 
        button.onclick = function() {
        document.getElementById("ShareScreen").remove();
        };
 
        fullScreenDiv.appendChild(button);
        button.style.top = '0px';
        button.style.right = '0px';
        button.style.marginRight = '3%';
        button.style.marginTop = '3%';
        button.style.userSelect = 'none';
        button.style.webkitUserSelect = 'none';

        document.body.style.webkitUserSelect = 'none';
        document.canvas.style.webkitUserSelect = 'none';

    }  
}

function shareFiles(filesArray) {
    if (navigator.canShare && navigator.canShare({ files: filesArray })) {
			navigator.share({
				files: filesArray
			}).then(
				() => console.log('Share was successful.')
			).catch(
				(error) => console.log('Sharing failed', error)
			);
	} else {
			//window.alert('Sorry, file sharing is not supported by your browser.');
    }
}


