// EventResponse.js
// script version: 2.0.8
var EventResponse = pc.createScript('Event Response');
 
// Helpers
// Create "this.keyList" containing all the playcanvas keyboard constants
this.keyList = [];
for (var prop in pc) 
{
    if (prop.startsWith("KEY_")) 
    {
        var keyObject = {};
        keyObject[prop] = pc[prop];
        this.keyList.push(keyObject);
    }
}

EventResponse.SWIPE_DIRECTION = {
    NONE: { value: 0, name: 'None' },
    UP: { value: 1, name: 'Up' },
    DOWN: { value: 2, name: 'Down' },
    LEFT: { value: 3, name: 'Left' },
    RIGHT: { value: 4, name: 'Right' }
};

EventResponse._swipedDirection = EventResponse.SWIPE_DIRECTION.NONE;

EventResponse.prototype.VectorMagnitude = function (p1, p2) 
{
    var dx = p1.x - p2.x;
    var dy = p1.y - p2.y;
    return Math.sqrt(dx * dx + dy * dy);
};

EventResponse.prototype.VectorAngleDegree = function (p1, p2) 
{
    return Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI;
};

EventResponse.prototype.getRandomInt = function (min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

EventResponse.prototype.getDescendantProp = function(obj, desc) 
{
    let arr = desc.split('.');
    while (arr.length) {
        obj = obj[arr.shift()];
    }
    return obj;
};

EventResponse.prototype.setDescendantProp = function(obj, desc, value) 
{
    var arr = desc.split('.');
    while (arr.length > 1) {
        obj = obj[arr.shift()];
    }
    return (obj[arr[0]] = value);
};

EventResponse.prototype.ShareData = function(shareData) {
    
    navigator.share(shareData)
    .then(function() 
    {
    }.bind(this))
    .catch(function(e) 
    {
        if(e.name == "NotAllowedError" && pc.platform.ios != null)
        {
            // iOS 14.0 and 14.1 never finish their share promise and fail on additonal navigator.share calls
            alert("This version of IOS does not allow multiple shares.\nPlease update your IOS to the latest version and try again");            
        }
    }.bind(this));
};

EventResponse.prototype.AskGyroPermission = function() {
    console.log("requesting permision");
    // Enable device motion for iOS
    if (typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceMotionEvent.requestPermission()
            .then(permissionState => {                
            if (permissionState === 'granted') {
                window.addEventListener('devicemotion', () => {});

            } 
        })
            .catch(console.error);
    } else {

    }

    // Enable device orientation for iOS
    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
        DeviceOrientationEvent.requestPermission()
            .then(permissionState => {
            if (permissionState === 'granted') {
                window.addEventListener('deviceorientation', (e) => {

                });
            }
        })
            .catch(console.error);
    } else {
        // handle regular non iOS 13+ devices
    }
};

// Attributes
EventResponse.attributes.add('Event', {
    type: 'json',
    schema: 
    [
        {
            name: 'Event',
            type: 'string',
            enum: 
            [
                {'Anim Event Called': 'Anim Event Called'}, 
                {'Do': 'Do'}, 
                {'Click Anywhere': 'Click Anywhere'},
                {'Click 3D': 'Click'},
                {'Click 2D Button': 'Click 2D Button'},
                {'Custom Event Called': 'Custom Event Called'},
                {'Events Completed': 'Events Completed'},
                //{'Looked At': 'Looked At'},
                {'Key Pressed': 'Key Pressed'},
                //{'Marker Detected': 'Marker Detected'},
                {'On Browser': 'On Browser'},
                {'On Collision': 'On Collision'},
                {'On Platform': 'On Platform'},
                {'On Platform Features': 'On Platform Features'},
                {'On Shake': 'On Shake'},
                {'Swiped': 'Swiped'},
                //{'Tween State Changed': 'Tween State Changed'},
            ],
            default: 'Click',
        },
        {
            name: 'Enabled',
            type: 'boolean',
            default: true, 
            description: 'If enabled then this event will fire responses.',
        },
        {
            name: 'SourceEntities',
            title: 'Source Entities',
            type: 'entity',
            array: true,
        },
        {
            name: 'EventName',
            title: 'Event Name',
            type: 'string',
        },
        {
            name: 'alternateCamera',
            title: 'Alternate Camera',
            type: 'entity',
            description: 'Leave empty to use the first enabled camera in Hierarchy.  Otherwise use this alternate camera for ray casting.',
        },
        {
            name: 'CollisionEvent', 
            type: 'string', 
            enum : 
            [
                { CollisionStart : 'collisionstart' },
                { CollisionEnd : 'collisionend' },
                { Contact : 'contact' },
                { TriggerEnter : 'triggerenter' },
                { TriggerLeave : 'triggerleave' },
            ], 
            default: 'collisionstart', 
            title: 'Collision Event', 
            description: 'Which collision event to subscribe to.',
        },
        {
            name: 'EnableCollisionTag',
            title: 'Enable Collision Tag',
            description: 'Enables collison by tag on object',
            type: 'boolean',
            default: false,
        },
        {
            name: 'CollisionTag',
            title: 'Collision Tag',
            type: 'string',
        },
        {
            name: 'EventsToComplete',
            title: 'Events To Complete',
            type: 'string',
            array: true,
        },
        {
            name: 'LookedAtSensitivity',
            title: 'Looked At Sensitivity',
            type: 'number',
        },
        {
            name: 'LookedAtTargets',
            title: 'Looked At Targets',
            type: 'entity',
            array: true,
        },
        {
            name: 'KeyDown_UP',
            title: 'Key Direction',
            type: 'number',
            enum: 
            [
                { 'KeyDown': 1 },
                { 'KeyUp': 2 },
            ],
            default: 1,
        },
        {
            name: 'EnableKeyRepeat',
            title: 'Enable Key Repeat',
            type: 'boolean',
        },
        {
            name: 'Key',
            type: 'number',
            enum: this.keyList,
        },
        {
            name: 'MarkerName',
            title: 'Marker Name',
            type: 'string',
        },
        {
            name: 'DeviceMotionButton',
            title: 'Request Device Motion Button',
            description: 'Get permission to use gyroscope on ios.',
            type: 'entity',
        },
        {
            name: 'ShakeThreshold',
            title: 'Shake Threshold',
            type: 'number',
            default: 10,
        },
        {
            name: 'ShakeTimeout',
            title: 'Shake Timeout',
            type: 'number',
            default: 1000,
            description: 'Delay between registering shakes.'
        },
        {
            name: 'SwipeDirection',
            title: 'Swipe Direction',
            type: 'number',
            enum: 
            [
                { 'Up': 1 },
                { 'Down': 2 },
                { 'Left': 3 },
                { 'Right': 4 },
            ],
            default: 1,
        },
        {
            name: 'MinSwipeAmount',
            title: "Minimum Swipe Length",
            type: "number",
            default: 1,
            description: "The length required for a swipe to be detected",
        },
        {
            name: 'TweenEvent',
            title: 'Tween Event',
            type: 'string',
            enum: 
            [
                { Complete: 'complete' },
                { Loop: 'loop' },
            ],
            default: 'complete',
        },
        {
            name: 'tweenEntity', 
            type: 'entity', 
            title: 'Entity With Tween', 
            description: 'The Entity to detect tween on.',  
        },
        {
            name: 'Browser', 
            type: 'string', 
            enum : 
            [
                { Chrome : 'Chrome' },
                { ChromeIOS : 'CriOS' },
                { Edge : 'Edg' },
                { Firefox : 'Firefox' },
                { FirefoxIOS : 'FxiOS' },
                { Opera : 'OPR' },
                { Safari : 'Safari' },
            ], 
            default: 'Chrome', 
            title: 'Browser', 
            description: 'Which browser to fire event on.',
        },
        {
            name: 'Platform', 
            type: 'string', 
            enum : 
            [
                { Android : 'android' },
                { Desktop : 'desktop' },
                { IOS : 'ios' },
                { Mobile : 'mobile' },
                { Windows : 'windows' },
                { Xbox : 'xbox' },
            ], 
            default: 'android', 
            title: 'Platform', 
            description: 'Which platform to fire event on.',
        },
        {
            name: 'platformFeatures', 
            type: 'string', 
            enum : 
            [
                { Gamepads : 'gamepads' },
                { Browser : 'browser' },
                { Touch : 'touch' },
                { Workers : 'workers' },
            ], 
            default: 'gamepads', 
            title: 'Platform Features', 
            description: 'Which platform feature to fire event on.',
        },
        {
            name: 'doOnce',
            title: 'Do Once',
            type: 'boolean',
            default: false,
        },   
        {
            name: 'Delay',
            type: 'number',
            placeholder: 'seconds',
            default: 0,
        },
    ]
});

EventResponse.attributes.add('Responses', {
    type: 'json',
    schema: 
    [
        {
            name: 'Response',
            type: 'string',
            default: 'Set Entity Enable',
            enum: 
            [
                {'Alert' : 'Alert'},
                {'Call Custom Event' : 'Call Custom Event'},
                {'Console Log' : 'Console Log'},
                {'Countdown' : 'Countdown'},
                {'Destroy Entity' : 'Destroy Entity'},
                {'Load Asset' : 'Load Asset'},
                {'Load Scene' : 'Load Scene'},
                {'Open URL' : 'Open URL'},
                {'Play Random Sound' : 'Play Random Sound'},
                {'Reload Parent Page' : 'Reload Parent Page'},
                {'Screen Shake' : 'Screen Shake'},
                {'Select Random String' : 'Select Random String'},
                {'Set Animation State' : 'Set Animation State'},
                {'Set Anim Graph State' : 'Set Anim Graph State'},
                //'Set Anim State' : 'Set Anim State'},
                {'Set Anim Trigger' : 'Set Anim Trigger'},
                {'Set Anim Integer' : 'Set Anim Integer'},
                {'Set Anim Float' : 'Set Anim Float'},
                {'Set Anim Boolean' : 'Set Anim Boolean'},
                {'Set Audio State' : 'Set Audio State'},
                {'Set Audio Volume' : 'Set Audio Volume'},
                {'Set Custom Float' : 'Set Custom Float'},
                //{'Set Custom Vec3' : 'Set Custom Vec3'},
                {'Set Entity Enable' : 'Set Entity Enable'},
                {'Set Material' : 'Set Material'},
                {'Set Material Colour' : 'Set Material Colour'},
                {'Set Material Float' : 'Set Material Float'},
                {'Set Material Parameter' : 'Set Material Parameter'},
                {'Set Material Texture' : 'Set Material Texture'}, 
                {'Set Parent' : 'Set Parent'},
                {'Set Particle State' : 'Set Particle State'},
                {'Set Transform' : 'Set Transform'},
                {'Set Zebrar Middleware' : 'Set Zebrar Middleware'},
                {'Share URL' : 'Share URL'},
                //{'Set Tween State': 'Set Tween State'},
                {'Typing Transition': 'Typing Transition'},
                {'Tween Audio Volume': 'Tween Audio Volume'},
                {'Tween Custom Float': 'Tween Custom Float'},
                //{'Tween Custom Vec3': 'Tween Custom Vec3'},
                {'Tween Material Colour': 'Tween Material Colour'},
                {'Tween Material Float': 'Tween Material Float'},
                {'Tween Material Offset': 'Tween Material Offset'},
                {'Tween Material Tiling': 'Tween Material Tiling'},
                {'Tween Position': 'Tween Position'},
                {'Tween Rotation': 'Tween Rotation'},
                {'Tween Scale': 'Tween Scale'},
                {'Rigidbody Impulse': 'Rigidbody Impulse'},
                {'Unload Asset': 'Unload Asset'},
            ]
        },
        {
            name: 'Enabled',
            type: 'boolean',
            default: true, 
            description: 'If enabled then this response will respond to the above event.',
        },
        {
            name: 'entitiesToEffect', 
            type: 'entity',
            array: true, 
            title: 'Entities To Effect', 
            description: 'The Entities to effect',
        },
        {
            name: 'assetsToEffect', 
            type: 'asset',
            array: true, 
            title: 'Assets To Effect', 
            description: 'The Assets to effect',
        },
        {
            name: 'debugString',
            title: 'Debug String',
            type: 'string',
        },
        {
            name: 'CustomEventName',
            title: 'Custom Event Name',
            type: 'string',
        },
        {
            name: 'sceneName',
            title: 'Scene Name',
            type: 'string',
        },
        {
            name: 'enableURL',
            title: 'Enable URL',
            type: 'boolean',
            description: 'If enabled URL will be shared',
        },
        {
            name: 'newTab',
            title: 'New Tab',
            type: 'boolean',
        },
        {
            name: 'URL',
            type: 'string',
        },
        {
            name: 'enableShareText',
            title: 'Enable Share Text',
            type: 'boolean',
            description: 'If enabled text will be shared.',
        },
        {
            name: 'shareText',
            title: 'Share Text',
            type: 'string',
        },
        {
            name: 'enableShareTitle',
            title: 'Enable Share Title',
            type: 'boolean',
            description: 'If enabled title will be shared.',
        },
        {
            name: 'shareTitle',
            title: 'Share Title',
            type: 'string',
        },
        {
            name: 'fallbackURLiOS',
            title: 'Fallback URL iOS',
            type: 'string',
        },
        {
            name: 'fallbackURLAndroid',
            title: 'Fallback URL Android',
            type: 'string',
        },
        {
            name: 'fallbackURLOther',
            title: 'Fallback URL Other',
            type: 'string',
        },
        {
            name: 'entityToEffect',
            title: 'Entity To Effect',
            type: 'entity',
            description: 'The Entity to effect',
        },
        
        {
            name: 'play',
            title: 'Play', 
            type: 'boolean', 
            description: 'Enable to play animation on receiving event',
        },
        {
            name: 'audioClips',
            type: "asset", 
            title:'Audio Clips',
            array: true,
            description: 'Audio clips to play.',
        },
        {
            name: 'animationName', 
            type: 'string', 
            title:'Animation Name', 
            description: 'Name of animation to play.',
        },
        {
            name: 'animTransTime', 
            type: 'number', 
            title:'Anim Transition Time', 
            description: 'Time to transition to animation.',
            default: 0
        },
        {
            name: 'animOffset', 
            type: 'number', 
            title:'Anim Offset', 
            description: 'Offset value between 0 and 1',
            default: 0
        },
        {
            name: 'blendTime', 
            type: 'number', 
            title: 'Blend Time', 
            description: 'The time in seconds to blend from the current animation state to the start of the animation being set.'
        },
        {
            name: 'typingSpeed', 
            type: 'number', 
            title: 'Typing Speed', 
            description: 'The delay between each letter.',
            default: 50
        },
        {
            name: 'typingString', 
            type: 'string', 
            title: 'Typing String', 
            description: 'The string used for typing effect.'
        },
        {
            name: 'loopAnim', 
            type: 'boolean', 
            default: false, 
            title: 'Loop (anim)', 
            description: 'Enable this to set the loop property of animation component'
        },
        {
            name: 'isPlaying', 
            type: 'boolean', 
            title: 'Is Playing', 
            description: 'Set to disabled to stop anim',
        },
        {
            name: 'reset', 
            type: 'boolean', 
            default: false, 
            title: 'Reset', 
            description: 'Set to disabled to stop anim',
        },
        {
            name: 'animationTrigger', 
            type: 'string', 
            title: 'Trigger Name', 
            description: 'The value of trigger in animation graph that you want to effect.',
        },
        {
            name: 'animationFloatName', 
            type: 'string', 
            title: 'Float Name', 
            description: 'The name value of the float in a animation graph that you want to effect.',
        },
        {
            name: 'animationIntegerName', 
            type: 'string', 
            title: 'Integer Name', 
            description: 'The name of the integer in a animation graph that you want to effect.',
        },
        {
            name: 'animationValue', 
            type: 'number', 
            title: 'Animation Value', 
            description: 'The value in a animation graph that you want to effect.',
        },
        {
            name: 'animationBooleanName', 
            type: 'string', 
            title: 'Boolean Name', 
            description: 'The name of a boolean in a animation graph that you want to effect.',
        },
        {
            name: 'animationBoolean', 
            type: 'boolean', 
            title: 'Boolean Value', 
            description: 'The value of a boolean in a animation graph that you want to effect.',
        },
        {
            name: 'customFloatPropertyPath', 
            type: 'string', 
            title: 'Property Path (number)', 
            description: 'eg. script.bloom.bloomIntensity',
        },
        {
            name: 'materialName',
            title: 'Material Name',
            type: 'string',
            default: ''
        },
        {
            name: 'soundSlot', 
            type: 'string', 
            title: 'Sound Slot', 
            description: 'The name of sound slot on entity selected',
            default: 'Slot 1',
        },
        {
            name: 'soundState', 
            type: 'string', 
            enum : 
            [
                { Play : 'play' },
                { Pause : 'pause' },
                { Resume : 'resume' },
                { Stop : 'stop' },
            ], 
            default: 'play', 
            title: 'Sound', 
            description: 'Which float property to effect.',
        },
        {
            name: 'volume', 
            type: 'number', 
            title: 'Audio Volume', 
            default: 1,
        },
        {
            name: 'newEnabledState',
            title: 'Enabled State To Set',
            type: 'number',
            enum: 
            [
                { 'Enable': 1 },
                { 'Disable': 2 },
                { 'Toggle': 3 }
            ],

            default: 1,
        },
        {
            name:"material", 
            type: "asset", 
            assettype: "material", 
            title: "Material",
        },
        {
            name: 'materialColour', 
            type: 'rgb', 
            default: [0, 0, 0], 
            title: 'Material Colour', 
            description: 'The material colour used for entities',
        },
        {
            name: 'colourProperty', 
            type: 'string', 
            enum : 
            [
                { Ambient : 'ambient' },
                { Diffuse : 'diffuse' },
                { Specular : 'specular' },
                { Emissive : 'emissive' },
            ], 
            default: 'diffuse', 
            title: 'Property Name (colour)', 
            description: 'Which Colour property to effect.',
        },
        {
            name: 'materialParameter', 
            type: 'string', 
            title: 'Material Parameter',
        },
        {
            name: 'floatValue', 
            type: 'number', 
            title: 'Value', 
            default: 1,
        },
        {
            name: 'floatProperty', 
            type: 'string', 
            enum : 
            [
                { Glossiness : 'shininess' },
                { EmissiveIntensity : 'emissiveIntensity' },
                { Opacity : 'opacity' },
                { AlphaTest : 'alphaTest' },
                { Reflectivity : 'reflectivity' },
                { Refraction : 'refraction' },
                { IndexOfRefraction : 'refractionIndex' },
            ], 
            default: 'opacity', 
            title: 'Property Name (number)', 
            description: 'Which float property to effect.',
        },
        {
            name: 'randomString',
            title: 'Random String',
            type: 'string',
            array: true
        },
        {
            name: 'randomPitch', 
            type: 'boolean', 
            title: 'Random Pitch', 
            description: 'Enable Random Pitch',
        },
        {
            name: 'minPitch', 
            type: 'number', 
            title: 'Min Pitch', 
            default: 1,
        },
        {
            name: 'maxPitch', 
            type: 'number', 
            title: 'Max Pitch', 
            default: 2,
        },
        {
            name: 'textureProperty', 
            type: 'string', 
            enum : 
            [
                { Ambient : 'aoMap' },
                { Diffuse : 'diffuseMap' },
                { Specular : 'specularMap' },
                { Glosiness : 'glossMap' },
                { Emissive : 'emissiveMap' },
                { Opacity : 'opacityMap' },
                { Normals : 'normalMap' },
                { HeightMap : 'heightMap' },
                { SphereMap : 'sphereMap' },
                { CubeMap : 'cubeMap' },
                { LightMap : 'lightMap' },
            ], 
            default: 'diffuse', 
            title: 'Property Name (texture)', 
            description: 'Which Texture property to effect.',
        },
        {
            name:"textureMap", 
            type: "asset", 
            assettype: "texture", 
            title: "Texture",
        },
        {
            name: 'keepPos', 
            type: 'boolean', 
            title: 'Keep Position',
            default: false 
        },
        {
            name: 'parentEntity', 
            type: 'entity', 
            title: 'Parent Entity', 
            description: 'Sets entitiesToEffect to children of this entity. If it is left empty all entitiesToEffect will get unparented.',
        },
        {
            name: 'additivePos', 
            type: 'boolean', 
            default: false, 
            title: 'Additive Pos',
        },
        {
            name: 'additiveRot', 
            type: 'boolean', 
            default: false, 
            title: 'Additive Rot',
        },
        {
            name: 'additiveScale', 
            type: 'boolean', 
            default: false, 
            title: 'Additive Scale',
        },
        {
            name: 'setPosition',
            type: 'boolean', 
            default: false, 
            title: 'Set Position', 
            description: 'Set to enabled for entity position to be set.',
        },
        {
            name: 'impulseVector', 
            type: 'vec3', 
            default: [0, 0, 0], 
            title: 'Impulse Vector', 
            description: 'Impulse Vector',
        },
        {
            name: 'position', 
            type: 'vec3', 
            default: [0, 0, 0], 
            title: 'Position', 
            description: 'Set the entities world position',
        },
        {
            name: 'setRotation', 
            type: 'boolean', 
            default: false, 
            title: 'Set Rotation', 
            description: 'Set to enabled for entity Rotation to be set.',
        },
        {
            name: 'rotation', 
            type: 'vec3', 
            default: [0, 0, 0], 
            title: 'Rotation', 
            description: 'Set the entities world rotation',
        },
        {
            name: 'setScale', 
            type: 'boolean', 
            default: false, 
            title: 'Set Scale', 
            description: 'Set to enabled for entity scale to be set.',
        },
        {
            name: 'scale', 
            type: 'vec3', 
            default: [1, 1, 1], 
            title: 'Scale', 
            description: 'Set the entities scale',
        },
        {
            name: 'space', 
            type: 'string', 
            default: 'world', 
            title: 'Transform Space', 
            description: 'Effect entities in world space or local space', enum: [{World: 'world'}, {Local: 'local'}],
        },
        {
            name: 'relativePos', 
            type: 'boolean', 
            default: false, 
            title: 'Relative Position', 
            description: 'To and from are relative to objects Position.',
        },
        {
            name: 'relativeRot', 
            type: 'boolean', 
            default: false, 
            title: 'Relative Rotation', 
            description: 'To and from are relative to objects Rotation.',
        },
        {
            name: 'relativeScale', 
            type: 'boolean', 
            default: false, 
            title: 'Relative Scale', 
            description: 'To and from are relative to objects Scale.',
        },
        {
            name: 'useFrom', 
            type: 'boolean', 
            default: false, 
            title: 'Use From', 
            description: 'At least one of "Use To" or "Use From" has to be active.  Both can also be active.',
        },
        {
            name: 'animateFromRGB', 
            type: 'rgb', 
            default: [0, 0, 0], 
            title: 'Animate From (RGB)', 
            description: 'Source colour to tween from',
        },
        {
            name: 'animateFromNumber', 
            type: 'number', 
            default: 1, 
            title: 'Animate From (number)', 
            description: 'Source value to tween from',
        },
        {
            name: 'animateFromEnt', 
            type: 'entity', 
            title: 'Animate From Entity', 
            description: 'Destination entity',
        },
        {
            name: 'animateFromVec3', 
            type: 'vec3', 
            default: [0, 0, 0], 
            title: 'Animate From (Vec3)', 
            description: 'Source position to tween from',
        },
        {
            name: 'aoMap', 
            type: 'boolean',  
        },
        {
            name: 'clearCoatGlossMap', 
            type: 'boolean',  
        },
        {
            name: 'clearCoatMap', 
            type: 'boolean',  
        },
        {
            name: 'clearCoatNormalMap', 
            type: 'boolean',  
        },
        {
            name: 'clearCoatDetailMap', 
            type: 'boolean',  
        },
        {
            name: 'diffuseMap', 
            type: 'boolean',  
        },
        {
            name: 'emissiveMap', 
            type: 'boolean',  
        },
        {
            name: 'glossMap', 
            type: 'boolean',  
        },
        {
            name: 'heightMap', 
            type: 'boolean',  
        },
        {
            name: 'lightMap', 
            type: 'boolean',  
        },
        {
            name: 'metalnessMap', 
            type: 'boolean',  
        },
        {
            name: 'normalDetailMap', 
            type: 'boolean',  
        },
        {
            name: 'normalMap', 
            type: 'boolean',  
        },
        {
            name: 'opacityMap', 
            type: 'boolean',  
        },
        {
            name: 'specularMap', 
            type: 'boolean',  
        },
        {
            name: 'animateFromVec2', 
            type: 'vec2', 
            default: [0, 0], 
            title: 'Animate From (Vec2)', 
        },
        {
            name: 'volumeFrom', 
            type: 'number', 
            default: 1, 
            title: 'Volume From', 
            description: 'Source value to tween from',
        },        
        {
            name: 'useTo', 
            type: 'boolean', 
            default: true, 
            title: 'Use To', 
            description: 'At least one of "Use To" or "Use From" has to be active.  Both can also be active.',
        },
        {
            name: 'animateToRGB', 
            type: 'rgb', 
            default: [0, 0, 0], 
            title: 'Animate To (RGB)', 
            description: 'Destination colour to tween towards',
        },
        {
            name: 'animateToNumber', 
            type: 'number', 
            default: 0, 
            title: 'Animate To (number)', 
            description: 'Destination value to tween towards',
        },
        {
            name: 'animateToEnt', 
            type: 'entity', 
            title: 'Animate To Entity', 
            description: 'Destination entity',
        },
        {
            name: 'animateToVec3', 
            type: 'vec3', 
            default: [0, 0, 0], 
            title: 'Animate To (Vec3)', 
            description: 'Destination position to tween towards',
        },
        {
            name: 'animateToVec2', 
            type: 'vec2', 
            default: [0, 0], 
            title: 'Animate To (Vec2)', 
        },
        {
            name: 'volumeTo', 
            type: 'number', 
            default: 0, 
            title: 'Volume To', 
            description: 'Destination value to tween towards',
        },
        {
            name: 'duration', 
            type: 'number', 
            default: 1.0, 
            title: 'Duration', 
            description: 'Time in seconds for tween to complete',
        },
        {
            name: 'easing', 
            type: 'string', 
            enum : 
            [
                { Linear : 'Linear' },
                { QuadraticIn : 'QuadraticIn' },
                { QuadraticOut : 'QuadraticOut' },
                { QuadraticInOut : 'QuadraticInOut' },
                { CubicIn : 'CubicIn' },
                { CubicOut : 'CubicOut' },
                { CubicInOut : 'CubicInOut' },
                { QuarticIn : 'QuarticIn' },
                { QuarticOut : 'QuarticOut' },
                { QuarticInOut : 'QuarticInOut' },
                { QuinticIn : 'QuinticIn' },
                { QuinticOut : 'QuinticOut' },
                { QuinticInOut : 'QuinticInOut' },
                { SineIn : 'SineIn' },
                { SineOut : 'SineOut' },
                { SineInOut : 'SineInOut' },
                { ExponentialIn : 'ExponentialIn' },
                { ExponentialOut : 'ExponentialOut' },
                { ExponentialInOut : 'ExponentialInOut' },
                { CircularIn : 'CircularIn' },
                { CircularOut : 'CircularOut' },
                { CircularInOut : 'CircularInOut' },
                { BackIn : 'BackIn' },
                { BackOut : 'BackOut' },
                { BackInOut : 'BackInOut' },
                { BounceIn : 'BounceIn' },
                { BounceOut : 'BounceOut' },
                { BounceInOut : 'BounceInOut' },
                { ElasticIn : 'ElasticIn' },
                { ElasticOut : 'ElasticOut' },
                { ElasticInOut : 'ElasticInOut' }
            ],
            default : 'Linear', 
            title: 'Easing Type',
        },
        {
            name: 'delay', 
            type: 'number', 
            default: 0, 
            title: 'Delay', 
            placeholder: 'seconds',
            description: 'Delay in seconds before starting this response.',
        },
        {
            name: 'loopTween', 
            type: 'boolean', 
            default: false, 
            title: 'Loop (tween)', 
            description: 'To loop a tween forever call tween.loop(true).',
        },
        
        {
            name: 'yoyo', 
            type: 'boolean', 
            default: false, 
            title: 'YoYo', 
            description: 'To make a tween play in reverse after it finishes call tween.yoyo(true). Note that to actually see the tween play in reverse in the end, you have to either repeat the tween at least 2 times or set it to loop forever. E.g. to only play a tween from start to end and then from end to start 1 time you need to do: tween.yoyo(true).repeat(2);',
        },
        {
            name: 'repeat', 
            type: 'number', 
            default: 2, 
            title: 'Repeat', 
            description: 'To repeat a tween count times call tween.repeat(count).',
        },
        
        { 
            name: 'shakeDuration', 
            title: 'Shake Duration (sec)',
            type: 'number', 
            default: 0.5,
            placeholder: 'seconds',
        }, 
        { 
            name: 'shakePower', 
            title: 'Shake Power',
            type: 'number', 
            default: 0.1,
            min: 0,
            max: 1,
        },        
        { 
            name: 'shakeMaxPercent', 
            title: 'Shake Max %',
            type: 'number', 
            default: 1.5,
        },
        { 
            name: 'setPageBackgroundColor', 
            title: 'Set Page Background Colour',
            type: 'boolean', 
            default: false,
        },
        { 
            name: 'documentPageColor', 
            title: 'Page Background Colour',
            type: 'rgba',
        },
        { 
            name: 'screenSpace', 
            title: 'Screen Space',
            type: 'boolean',
        },
        {
            name: 'particleState', 
            type: 'string', 
            default: 'play', 
            title: 'Particle State', 
            description: 'Set the state of particle component', enum: 
            [
                {Play: 'play'}, 
                {Stop: 'stop'}, 
                {Pause: 'pause'}, 
                {UnPause: 'unpause'}, 
                {Reset: 'reset'},
                {'Toggle Paused': 'togglePaused'}
            ],
        },
        {
            name: 'doOnce',
            title: 'Do Once',
            type: 'boolean',
            default: false,
        },         
        {
            name: 'usePostUpdate', 
            type: 'boolean', 
            default: false, 
            title: 'Use Post Update', 
            description: 'If checked delay tween effect until postUpdate.',
        },
        {
            name: 'zebrarState', 
            type: 'string', 
            default: 'gameLoaded', 
            title: 'Zebrar State', 
            description: 'Set the state of the zebrar middleware', enum: 
            [
                {'Game Loaded': 'gameLoaded'}, 
                {'Game Finished': 'gameFinished'}
            ],
        },
    ],
    array: true,
});

EventResponse.prototype.onStateChanged = function(state) {        
this.completedEvents = [];
    this.tweens = {};
    this.tweenData = {};

    // dump state of Script Attributes
    //console.log(this.Event.Event);
    // Loop through responses
    //this.Responses.forEach(r => 
    //{
    //    console.log(' - ' + r.Response);    
    //});
    if (this.Event.Enabled)
    {
        if (this.Event.Event == 'Do')
        {
            this.subscribe_Do();
        }
    }
       
};

EventResponse.prototype.initialize = function() 
{
    this.onStateChanged();
    this.on('state', this.onStateChanged, this);

    if (this.Event.Enabled)
    {
        switch (this.Event.Event)
        {
            case 'Anim Event Called':
                this.subscribe_Anim_Event_Called();
                break;
            case 'Click Anywhere':
                this.subscribe_Click_Anywhere();
                //this.subscribe_Swiped();
                break;
            case 'Click': // 3d obj
                this.subscribe_Click();
                //this.subscribe_Swiped();
                break;
            case 'Click 2D Button':
                this.subscribe_Click_2D_Button();
                //this.subscribe_Swiped();
                break;
            case 'Custom Event Called':
                this.subscribe_Custom_Event_Called();
                break;
            case 'Events Completed':
                this.subscribe_Events_Completed();
                break;
            case 'Looked At':
                this.subscribe_Looked_At();
                break;
            case 'Key Pressed':
                this.subscribe_Key_Pressed();
                break;
            case 'Marker Detected':
                this.subscribe_Marker_Detected();
                break;
            case 'On Browser':
                this.subscribe_On_Browser();
                break;
            case 'On Collision':
                this.subscribe_On_Collision();
                break;
            case 'On Platform':
                this.subscribe_On_Platform();
                break;
            case 'On Platform Features':
                this.subscribe_On_Platform_Features();
                break;
            case 'On Shake':
                this.subscribe_On_Shake();
                break;
            case 'Swiped':
                this.subscribe_Swiped();
                break;
            case 'Tween State Changed':
                this.subscribe_Tween_State_Changed();
                break;
            default:
        }
    }
    
    // kill app wide custom event subscription if destroyed
    if (this.Event.EventName) 
    {
        this.entity.on('destroy', _ =>
        {
            this.app.off(this.Event.EventName);
            //console.log("Unsubscribe from " + this.Event.EventName);
        });
    }
};

EventResponse.prototype.update = function(dt) 
{
    if (this.ScreenShake)
    {
        this.perform_Screen_Shake_Update(dt);
    }
};

EventResponse.prototype.subscribe_Anim_Event_Called = function()
{
    const entToEffectNotNull = this.Event.SourceEntities.filter(e => e && e.anim);
    if (entToEffectNotNull.length === 0)
    {
        if(this.entity.anim)
        {
            this.entity.anim.on(this.Event.EventName,this.handle_Anim_Event_Called, this);
        }
    }
    else
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            entToEffectNotNull[i].anim.on(this.Event.EventName,this.handle_Anim_Event_Called, this);
        }
    }
};

// Subscribe to events
EventResponse.prototype.subscribe_Do = function() 
{
    this.handle_Do();
};

EventResponse.prototype.subscribe_Click_Anywhere = function() 
{
    this.app.mouse.on(pc.EVENT_MOUSEDOWN, this.handle_Click_Anywhere, this);
    
    //this.app.touch.on(pc.EVENT_TOUCHSTART, this.handle_Click_Anywhere, this);
};

EventResponse.prototype.subscribe_Click = function() 
{
    // detect a negative scale component... collision doesn't work if scale is not positive
    let sc = this.entity.getLocalScale();
    if ((sc.x <= 0) || (sc.y <= 0) || (sc.z <= 0))
    {
        console.error('EvtClick3D failed to subscribe.  Found scale component with 0 or less on ' + this.entity.name);
        return;
    }
    
    this.app.mouse.on(pc.EVENT_MOUSEDOWN, this.handle_Click, this);
    //this.app.touch.on(pc.EVENT_TOUCHSTART, this.handle_Click, this); 
    
    const entToEffectNotNull = this.Event.SourceEntities.filter(e => e && e.model || e.collision || e.render);
    if (entToEffectNotNull.length === 0)
    {
        if (!this.entity.collision && this.entity.model)
        {
            this.entity.addComponent('collision', {
                    type: 'mesh',
                    model: this.entity.model.model.clone()
            });
            console.log("EvtClick3D: Added required collision component for " + this.entity.name);
        }

        if (!this.entity.collision && this.entity.render)
        {
            this.entity.addComponent('collision', {
                    type: 'mesh',
                    renderAsset: this.entity.render._assetReference.asset,
            });
            console.log("EvtClick3D: Added required collsion component for " + this.entity.name);
        }
    }
    else
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            if (!entToEffectNotNull[i].collision && entToEffectNotNull[i].model)
            {
                entToEffectNotNull[i].addComponent('collision', {
                        type: 'mesh',
                        model: entToEffectNotNull[i].model.model.clone()
                });
                console.log("EvtClick3D: Added required collsion component for " + entToEffectNotNull[i].name);
            }
            
            if (!entToEffectNotNull[i].collision && entToEffectNotNull[i].render)
            {
                entToEffectNotNull[i].addComponent('collision', {
                        type: 'mesh',
                        renderAsset: entToEffectNotNull[i].render._assetReference.asset,
                });
                console.log("EvtClick3D: Added required collsion component for " + entToEffectNotNull[i].name);
            }         
        }
    }
};

EventResponse.prototype.subscribe_Click_2D_Button = function() 
{
    // console.log("Subscribing to click event for 2D button: " + this.entity.name);
    //this.app.mouse.on(pc.EVENT_MOUSEDOWN, this.handle_Click, this);
    const entToEffectNotNull = this.Event.SourceEntities.filter(e => e && e.button);
    if(entToEffectNotNull.length > 0){
        entToEffectNotNull.forEach(e => 
        {
            e.button.on('click', this.handle_Click_2D_Button, this);
        });
    }
    else
    {

        if(this.entity.button)
        {
            this.entity.button.on('click', this.handle_Click_2D_Button, this);
        }
    }

};

EventResponse.prototype.subscribe_Events_Completed = function() 
{
    if (this.Event.EventsToComplete.length > 0)
    {
        for (var i=0; i<this.Event.EventsToComplete.length; i++)
        {
            this.app.on(this.Event.EventsToComplete[i], this.handle_Events_Completed.bind(this,this.Event.EventsToComplete[i]));
        }
    }
    else
    {
        console.log("No events to complete");    
    }
    
};

EventResponse.prototype.subscribe_Custom_Event_Called = function() 
{
    this.app.on(this.Event.EventName, this.handle_Custom_Event_Called, this);
};

EventResponse.prototype.subscribe_Looked_At = function() 
{

};

EventResponse.prototype.subscribe_Key_Pressed = function() 
{
    if (this.Event.KeyDown_UP === 1)
    {
        this.app.keyboard.on(pc.EVENT_KEYDOWN, this.handle_Key_Pressed, this);
    }
    else
    {
        this.app.keyboard.on(pc.EVENT_KEYUP, this.handle_Key_Pressed, this);
    } 
};

EventResponse.prototype.subscribe_Marker_Detected = function() 
{

};

EventResponse.prototype.subscribe_On_Browser = function()
{
    let userAgent = navigator.userAgent;
    if (userAgent.match(this.Event.Browser))
    {
        this.handle_Do();
    }
};

EventResponse.prototype.subscribe_On_Collision = function()
{
    const entToEffectNotNull = this.Event.SourceEntities.filter(e => e && e.collision);
    if (entToEffectNotNull.length > 0){
        entToEffectNotNull.forEach(e => 
                                   {
            e.collision.on(this.Event.CollisionEvent, this.handle_Collision, this);
        });
    }
    else
    {
        if (this.entity.collision)
        {
            this.entity.collision.on(this.Event.CollisionEvent, this.handle_Collision, this);
        }
    }
};

EventResponse.prototype.subscribe_On_Platform = function()
{
    switch(this.Event.Platform) 
    {
        case 'android':
            if(pc.platform.android)
            {
                this.handle_Do();
            }
            break;
        case 'desktop':
            if(pc.platform.desktop)
            {
                this.handle_Do();
            }
            break;
        case 'ios':
            if(pc.platform.ios)
            {
                this.handle_Do();
            }
            break;
        case 'mobile':
            if(pc.platform.mobile)
            {
                this.handle_Do();
            }
            break;
        case 'windows':
            if(pc.platform.windows)
            {
                this.handle_Do();
            }
            break;
        case 'xbox':
            if(pc.platform.xbox)
            {
                this.handle_Do();
            }
            break;
        default:
            break;
    }         
};

EventResponse.prototype.subscribe_On_Platform_Features = function()
{
    switch(this.Event.platformFeatures) {
        case 'gamepads':
            if(pc.platform.gamepads)
            {
                this.handle_Do();
            }
            break;
        case 'browser':
            if(pc.platform.browser)
            {
                this.handle_Do();
            }
            break;
        case 'touch':
            if(pc.platform.touch)
            {
                this.handle_Do();
            }
            break;
        case 'workers':
            if(pc.platform.workers)
            {
                this.handle_Do();
            }
            break;
        default:
            break;
    }
};

EventResponse.prototype.subscribe_On_Shake = function()
{
   
    if (this.Event.Enabled)
    {
        var options = {
        };

        options.threshold = this.Event.ShakeThreshold;
        options.timeout = this.Event.ShakeTimeout;

        var shakeEvent = new Shake(options);

        if (this.Event.DeviceMotionButton)
        {
            console.log("request gyro");
            this.Event.DeviceMotionButton.button.on('click', this.AskGyroPermission, this);
        }

        shakeEvent.start();
       
        window.addEventListener('shake', shakeEventDidOccur.bind(this), false);

        //function to call when shake occurs
        function shakeEventDidOccur () {
            this.handle_Do();
            if (this.Event.doOnce){
                window.removeEventListener('shake', shakeEventDidOccur, false);
            } 
        }
    }
    
    
};

EventResponse.prototype.subscribe_Swiped = function() 
{
    if (this.app.touch) 
    {
        this.app.touch.on(pc.EVENT_TOUCHSTART, this.handle_Swipe_TouchDown, this);
        this.app.touch.on(pc.EVENT_TOUCHMOVE, this.handle_Swipe_onTouchMove, this);
        this.app.touch.on(pc.EVENT_TOUCHEND, this.handle_Swipe_onTouchUp, this);
    }
    if (this.app.mouse) 
    {
        this.app.mouse.on(pc.EVENT_MOUSEDOWN, this.handle_Swipe_onMouseDown, this);
        //this.app.mouse.on(pc.EVENT_MOUSEMOVE, this.handle_Swipe_onMouseMove, this);
        this.app.mouse.on(pc.EVENT_MOUSEUP, this.handle_Swipe_onMouseUp, this);
    }
};

EventResponse.prototype.subscribe_Tween_State_Changed = function() 
{

};

// Handle Events
EventResponse.prototype.handle_Anim_Event_Called = function() 
{
    if (this.entity.enabled && this.Event.Enabled)
    {
        setTimeout(_ => this.Responses.forEach(r => this.handle_Response(r)), this.Event.Delay * 1000, this);
        if (this.Event.doOnce)
        {
            this.Event.Enabled = false;
        }
    }
};

EventResponse.prototype.handle_Do = function() 
{
    if (this.entity.enabled && this.Event.Enabled)
    {
        setTimeout(_ => this.Responses.forEach(r => this.handle_Response(r)), this.Event.Delay * 1000, this);
        if (this.Event.doOnce)
        {
            this.Event.Enabled = false;
        }
    }
};

EventResponse.prototype.handle_Click_Anywhere = function(e) 
{
    if (this.entity.enabled && this.Event.Enabled)
    {
        setTimeout(_ => {this.Responses.forEach(r => this.handle_Response(r));}, this.Event.Delay * 1000, this);
        if (this.Event.doOnce)
        {
            this.Event.Enabled = false;
        }
    }
};


EventResponse.prototype.handle_Click = function(e) 
{
    if (this.entity.enabled && this.Event.Enabled)
    {
        if (window.Ammo) // Is Ammo even installed?!
        {
            var cam = (this.Event.alternateCamera) ? this.Event.alternateCamera.camera : this.app.root.findOne(e => (e.camera && e.enabled)).camera;
            if (cam)
            {
                var from = cam.screenToWorld(e.x, e.y, cam.nearClip);
                var to = cam.screenToWorld(e.x, e.y, cam.farClip);

                var result = this.app.systems.rigidbody.raycastFirst(from, to);
                if (result) 
                {
                    const entToEffectNotNull = this.Event.SourceEntities.filter(e => e && e.model || e.collision || e.render);
                    if (entToEffectNotNull.length === 0)
                    {
                        if (this.entity.model || this.entity.render)
                        {
                            entToEffectNotNull.push(this.entity);
                        }
                    }
                    
                    if (entToEffectNotNull.includes(result.entity))
                    {
                        setTimeout(_ => {this.Responses.forEach(r => this.handle_Response(r));}, this.Event.Delay * 1000, this);
                        if (this.Event.doOnce)
                        {
                            this.Event.Enabled = false;
                        }           
                    }
                }
            }
            else
            {
                console.log("Zebrar Tools Click event can't find enabled camera for raycasting.  Click event ignored.");
            }
        }
        else
        {
            console.log("Zebrar Tools Click event uses Ammo.js, but it isn't included in the project yet.  Install via the editor.");
        }
        
    }
};

EventResponse.prototype.handle_Click_2D_Button = function(e) 
{
    if (this.entity.enabled && this.Event.Enabled)
    {
        setTimeout(_ => {this.Responses.forEach(r => this.handle_Response(r));}, this.Event.Delay * 1000, this);
        if (this.Event.doOnce)
        {
            this.Event.Enabled = false;
        }
    }
};

EventResponse.prototype.handle_Custom_Event_Called = function() 
{
    if (this.entity.enabled && this.Event.Enabled)
    {
        setTimeout(_ => this.Responses.forEach(r => this.handle_Response(r)), this.Event.Delay * 1000, this);
        if (this.Event.doOnce)
        {
            this.Event.Enabled = false;
        }
    }
};

EventResponse.prototype.handle_Collision = function(e) 
{
    if (this.entity.enabled && this.Event.Enabled)
    {
        if (this.Event.EnableCollisionTag)
        {
            if (e.other.tags.has(this.Event.CollisionTag))
            {
                setTimeout(_ => this.Responses.forEach(r => this.handle_Response(r)), this.Event.Delay * 1000, this);
            }
        }
        else
        {
            setTimeout(_ => this.Responses.forEach(r => this.handle_Response(r)), this.Event.Delay * 1000, this);
        }
        if (this.Event.doOnce)
        {
            this.Event.Enabled = false;
        }
    }
};

EventResponse.prototype.handle_Events_Completed = function(name) 
{
    if (this.completedEvents.length > 0)
    {
        if (!this.completedEvents.includes(name))
        {
            this.completedEvents.push(name);
        }
    }
    else
    {
        this.completedEvents.push(name);
    }

    let result = false;

    if (this.completedEvents.length === this.Event.EventsToComplete.length) {
        for (let i = 0; i < this.completedEvents.length; i++) {
            result = this.Event.EventsToComplete.indexOf(this.completedEvents[i]) !== -1;

            if (result === false) {
                break;
            }
        }
    }

    if (this.entity.enabled && this.Event.Enabled && result)
    {
        setTimeout(_ => this.Responses.forEach(r => this.handle_Response(r)), this.Event.Delay * 1000, this);
        if (this.Event.doOnce)
        {
            this.Event.Enabled = false;
        }
    }
};

EventResponse.prototype.handle_Looked_At = function() 
{
    if (this.entity.enabled && this.Event.Enabled)
    {
        setTimeout(_ => this.Responses.forEach(r => this.handle_Response(r)), this.Event.Delay * 1000, this);
        if (this.Event.doOnce)
        {
            this.Event.Enabled = false;
        }
    }
};

EventResponse.prototype.handle_Key_Pressed = function(e) 
{
    if (this.entity.enabled && this.Event.Enabled)
    {
        if (e.key === this.Event.Key)
        {
            if (this.Event.EnableKeyRepeat)
            {
                setTimeout(_ => this.Responses.forEach(r => this.handle_Response(r)), this.Event.Delay * 1000, this);
                e.event.preventDefault();
            }
            else
            {
                if (!(e.event.repeat))
                {
                    setTimeout(_ => this.Responses.forEach(r => this.handle_Response(r)), this.Event.Delay * 1000, this);
                    e.event.preventDefault();
                }
            }
        }
        if (this.Event.doOnce)
        {
            this.Event.Enabled = false;
        }
    }
};

EventResponse.prototype.handle_Marker_Detected = function() 
{
    if (this.entity.enabled && this.Event.Enabled)
    {
        setTimeout(_ => this.Responses.forEach(r => this.handle_Response(r)), this.Event.Delay * 1000, this);
        if (this.Event.doOnce)
        {
            this.Event.Enabled = false;
        }        
    }
};

EventResponse.prototype.SwipeSetInitialPos = function(x, y) 
{
    this._swipeInitialTouchPos = 
    {
        x: x,
        y: y
    };
};

EventResponse.prototype.SwipeUpdateSwipeDirection = function(x, y, released) 
{
    var endTouchPos = 
    {
        x: x,
        y: y
    };

    var mag = this.VectorMagnitude(this._swipeInitialTouchPos, endTouchPos);
    if (mag < this.Event.MinSwipeAmount)
    {
        EventResponse._swipedDirection = EventResponse.SWIPE_DIRECTION.NONE;
        return;
    }

    var degree = this.VectorAngleDegree(this._swipeInitialTouchPos, endTouchPos);

    if (degree < -135 || degree > 135) {
        EventResponse._swipedDirection = EventResponse.SWIPE_DIRECTION.LEFT;
    } else if (degree > -135 && degree < -45) {
        EventResponse._swipedDirection = EventResponse.SWIPE_DIRECTION.UP;
    } else if (degree > -45 && degree < 45) {
        EventResponse._swipedDirection = EventResponse.SWIPE_DIRECTION.RIGHT;
    } else if (degree > 45 && degree < 135) {
        EventResponse._swipedDirection = EventResponse.SWIPE_DIRECTION.DOWN;
    }

    //console.log(this.Event.SwipeDirection);
    //console.log(EventResponse._swipedDirection);
    
    if(released)
    {
        if (this.Event.SwipeDirection == EventResponse._swipedDirection.value)
        {
            this.handle_Swiped();
        }
        
        // now reset the current swipe direction
        EventResponse._swipedDirection = EventResponse.SWIPE_DIRECTION.NONE;
    }
};

EventResponse.prototype.handle_Swipe_TouchDown = function(e) 
{
    this.SwipeSetInitialPos(e.changedTouches[0].x, e.changedTouches[0].y);
    e.event.preventDefault();
};

EventResponse.prototype.handle_Swipe_onTouchMove = function(e) 
{
    this.SwipeUpdateSwipeDirection(e.changedTouches[0].x, e.changedTouches[0].y, false);
    e.event.preventDefault();
};

EventResponse.prototype.handle_Swipe_onTouchUp = function(e) 
{
   this.SwipeUpdateSwipeDirection(e.changedTouches[0].x, e.changedTouches[0].y, true);
    e.event.preventDefault();
};

EventResponse.prototype.handle_Swipe_onMouseDown = function(e) 
{
    if (e.button === pc.MOUSEBUTTON_LEFT) 
    {
        this.SwipeSetInitialPos(e.x, e.y);
    }
}; 

EventResponse.prototype.handle_Swipe_onMouseUp = function(e) 
{
    if (e.button === pc.MOUSEBUTTON_LEFT) 
    {
        this.SwipeUpdateSwipeDirection(e.x, e.y, true);
    }
};

EventResponse.prototype.handle_Swiped = function() 
{
    if (this.entity.enabled && this.Event.Enabled)
    {
        if(this.Event.Event !== 'Swiped')
        {
            // tap events are also subscribed to swipe events
            // so ignore this swipe
            return;
        }
        
        setTimeout(_ => this.Responses.forEach(r => this.handle_Response(r)), this.Event.Delay * 1000, this);
        if (this.Event.doOnce)
        {
            this.Event.Enabled = false;
        }        
    }
};

EventResponse.prototype.handle_Tween_State_Changed = function() 
{
    if (this.entity.enabled && this.Event.Enabled)
    {
        setTimeout(_ => this.Responses.forEach(r => this.handle_Response(r)), this.Event.Delay * 1000, this);
        if (this.Event.doOnce)
        {
            this.Event.Enabled = false;
        }
    }
};

// Responses
EventResponse.prototype.handle_Response = function(r) 
{
    if (r.Enabled)
    {
        if (r.doOnce)
        {
            r.Enabled = false;
        }
        console.log("Handling Response: " + r.Response + " " +this.entity.name);
        switch(r.Response)
        {
            case 'Alert':
                setTimeout(_ => this.perform_Alert(r), r.delay * 1000, this);
                break;
            case 'Call Custom Event':
                setTimeout(_ => this.perform_Call_Custom_Event(r), r.delay * 1000, this);
                break;
            case 'Console Log':
                setTimeout(_ => this.perform_Console_Log(r), r.delay * 1000, this);
                break;
            case 'Countdown':
                setTimeout(_ => this.perform_Countdown(r), r.delay * 1000, this);
                break;
            case 'Destroy Entity':
                setTimeout(_ => this.perform_Destroy_Entity(r), r.delay * 1000, this);
                break;
            case 'Load Asset':
                setTimeout(_ => this.perform_Load_Asset(r), r.delay * 1000, this);
                break;
            case 'Load Scene':
                setTimeout(_ => this.perform_Load_Scene(r), r.delay * 1000, this);
                break;            
            case 'Open URL':
                setTimeout(_ => this.perform_Open_URL(r), r.delay * 1000, this);
                break;
            case 'Play Random Sound':
                setTimeout(_ => this.perform_Play_Random_Sound(r), r.delay * 1000, this);
                break;
            case 'Reload Parent Page':
                setTimeout(_ => this.perform_Reload_Parent_Page(r), r.delay * 1000, this);
                break;
            case 'Screen Shake':
                setTimeout(_ => this.perform_Screen_Shake(r), r.delay * 1000, this);
                break;
            case 'Select Random String':
                setTimeout(_ =>  this.perform_Select_Random_String(r), r.delay * 1000, this);
                break;
            case 'Set Animation State':
                setTimeout(_ => this.perform_Set_Animation_State(r), r.delay * 1000, this);
                break;
            case 'Set Anim Graph State':
                setTimeout(_ => this.perform_Set_Anim_Graph_State(r), r.delay * 1000, this);
                break;
            case 'Set Anim State':
                setTimeout(_ => this.perform_Set_Anim_State(r), r.delay * 1000, this);
                break;
            case 'Set Anim Trigger':
                setTimeout(_ => this.perform_Set_Anim_Trigger(r), r.delay * 1000, this);
                break;
            case 'Set Anim Integer':
                setTimeout(_ => this.perform_Set_Anim_Integer(r), r.delay * 1000, this);
                break;
            case 'Set Anim Boolean':
                setTimeout(_ => this.perform_Set_Anim_Boolean(r), r.delay * 1000, this);
                break;
            case 'Set Anim Float':
                setTimeout(_ => this.perform_Set_Anim_Float(r), r.delay * 1000, this);
                break;
            case 'Set Audio State':
                setTimeout(_ => this.perform_Set_Audio_State(r), r.delay * 1000, this);
                break;
            case 'Set Audio Volume':
                setTimeout(_ => this.perform_Set_Audio_Volume(r), r.delay * 1000, this);
                break;
            case 'Set Custom Float':
                setTimeout(_ => this.perform_Set_Custom_Float(r), r.delay * 1000, this);
                break;
            case 'Set Custom Vec3':
                setTimeout(_ => this.perform_Set_Custom_Vec3(r), r.delay * 1000, this);
                break;
            case 'Set Entity Enable':
                setTimeout(_ => this.perform_Set_Entity_Enable(r), r.delay * 1000, this);
                break;
            case 'Set Material':
                setTimeout(_ => this.perform_Set_Material(r), r.delay * 1000, this);   
                break;
            case 'Set Material Colour':
                setTimeout(_ => this.perform_Set_Material_Colour(r), r.delay * 1000, this);
                break;
            case 'Set Material Float':
                setTimeout(_ => this.perform_Set_Material_Float(r), r.delay * 1000, this);
                break;
            case 'Set Material Parameter':
                setTimeout(_ => this.perform_Set_Material_Parameter(r), r.delay * 1000, this);
                break;
            case 'Set Material Texture':
                setTimeout(_ => this.perform_Set_Material_Texture(r), r.delay * 1000, this);
                break;
            case 'Set Parent':
                setTimeout(_ => this.perform_Set_Parent(r), r.delay * 1000, this);
                break;
            case 'Set Particle State':
                setTimeout(_ => this.perform_Set_Particle_State(r), r.delay * 1000, this);
                break;
            case 'Set Transform':
                setTimeout(_ => this.perform_Set_Transform(r), r.delay * 1000, this);
                break;
            case 'Set Tween State':
                setTimeout(_ => this.perform_Set_Tween_State(r), r.delay * 1000, this);
                break;
            case 'Set Zebrar Middleware':
                setTimeout(_ => this.perform_Set_Zebrar_Middleware(r), r.delay * 1000, this);
                break;
            case 'Share URL':
                setTimeout(_ => this.perform_Share_URL(r), r.delay * 1000, this);
                break;
            case 'Typing Transition':
                setTimeout(_ => this.perform_Typing_Transition(r), r.delay * 1000, this);
                break;
            case 'Tween Audio Volume':
                setTimeout(_ => this.perform_Tween_Audio_Volume(r), r.delay * 1000, this);
                break;
            case 'Tween Custom Float':
                setTimeout(_ => this.perform_Tween_Custom_Float(r), r.delay * 1000, this);
                break;
            case 'Tween Custom Vec3':
                setTimeout(_ => this.perform_Tween_Custom_Vec3(r), r.delay * 1000, this);
                break;
            case 'Tween Material Colour':
                setTimeout(_ => this.perform_Tween_Material_Colour(r), r.delay * 1000, this);
                break;
            case 'Tween Material Float':
                setTimeout(_ => this.perform_Tween_Material_Float(r), r.delay * 1000, this);
                break;
            case 'Tween Material Offset':
                setTimeout(_ => this.perform_Tween_Material_Offset(r), r.delay * 1000, this);
                break;
            case 'Tween Material Tiling':
                setTimeout(_ => this.perform_Tween_Material_Tiling(r), r.delay * 1000, this);
                break;
            case 'Tween Position':
                setTimeout(_ => this.perform_Tween_Position(r), r.delay * 1000, this);
                break;
            case 'Tween Rotation':
                setTimeout(_ => this.perform_Tween_Rotation(r), r.delay * 1000, this);
                break;
            case 'Tween Scale':
                setTimeout(_ => this.perform_Tween_Scale(r), r.delay * 1000, this);
                break;
            case 'Rigidbody Impulse':
                setTimeout(_ => this.perform_Rigidbody_Impulse(r), r.delay * 1000, this);
                break;
            case 'Unload Asset':
                setTimeout(_ => this.perform_Unload_Asset(r), r.delay * 1000, this);
                break;
            default:
        }
    }
};

EventResponse.prototype.perform_Destroy_Entity = function(r) {
    if (r.entitiesToEffect.length > 0)
    {
        for (var i=0; i<r.entitiesToEffect.length; i++)
        {
            r.entitiesToEffect[i].destroy();
        }
    }
    else
    {
        this.entity.destroy();
    }
};

EventResponse.prototype.perform_Alert = function(r) {
    alert(r.debugString);
};

EventResponse.prototype.perform_Call_Custom_Event = function(r) {
    //console.log(r.CustomEventName);
    this.app.fire(r.CustomEventName);
};

EventResponse.prototype.perform_Console_Log = function(r) {
    console.log(r.debugString);
};

EventResponse.prototype.perform_Countdown = function(r) {
    if (r.entitiesToEffect.length > 0)
    {

    }
};

EventResponse.prototype.perform_Load_Asset = function(r) {
    if (r.assetsToEffect.length > 0)
    {
        for (var i = 0; i < r.assetsToEffect.length; i++)
        {
            this.app.assets.once("load:" +  r.assetsToEffect[i].id, function (asset) {
                console.log("asset:"+asset.name+":loaded");
                pc.app.fire("asset:"+asset.name+":loaded");
                for (var i = 0; i < r.assetsToEffect.length; i++)
                {
                    if(!r.assetsToEffect[i].loaded)
                    {
                        return;
                    }
                }
                console.log("assets:loaded");
                pc.app.fire("assets:loaded");
            }.bind(r));
            this.app.assets.load(r.assetsToEffect[i]);
        }
    }
};

EventResponse.prototype.perform_Load_Scene = function(r) {
    // Get a reference to the scene's root object
    var oldHierarchy = this.app.root.findByName ('Root');
    
    // Get the path to the scene
    var scene = this.app.scenes.find(r.sceneName);
    
    // kill all the app wide events!
    if (this.app.keyboard) this.app.keyboard.off();
    if (this.app.mouse) this.app.mouse.off();
    if (this.app.touch) this.app.touch.off();
    if (pc.app._tweenManager) pc.app._tweenManager._tweens.forEach(t => t.stop()); // kill any left over tweens
    
    // Load the scenes entity hierarchy
    this.app.scenes.loadSceneHierarchy(scene.url, function (err, parent) {
        if (!err) {
            console.log("Scene loaded");
            oldHierarchy.destroy();
            console.log("Old scene destroyed");
        } else {
            console.log("Scene loaded with error!");
            console.error(err);
        }
    });
};

EventResponse.prototype.perform_Open_URL = function(r)
{
    if (r.fallbackURLiOS !== '' && this.isIOS())
    {
        console.log("Have iOS Fallback URL: " + r.fallbackURLiOS);
        console.log("IsIOS: " + this.isIOS());
        this.URL_Fallback(r.fallbackURLiOS, r.newTab);  
    }
    else if (r.fallbackURLAndroid !== '' && this.isAndroid())
    {
        console.log("Have Android Fallback URL: " + r.fallbackURLAndroid);
        console.log("IsAndroid: " + this.isAndroid());        
        this.URL_Fallback(r.fallbackURLAndroid, r.newTab);  
    }
    else if (r.fallbackURLOther !== '')
    {
        console.log("Have Other Fallback URL: " + r.fallbackURLOther);
        this.URL_Fallback(r.fallbackURLOther, r.newTab);
    }
    if (r.newTab)
    {
        window.open(r.URL,'_blank');
    }
    else
    {
        window.location = r.URL;
    } 
};

EventResponse.prototype.perform_Play_Random_Sound = function(r)
{
    var soundEntity;
    if(r.entityToEffect != null)
    {
        soundEntity = r.entityToEffect;
    }
    else
    {
        soundEntity = this.entity;
    }
     //remove existing slots
    for(var slotName in soundEntity.sound.slots)
    {
        soundEntity.sound.removeSlot(slotName);
    }
    if (r.audioClips.length > 0)
    {
    var randomIndex = this.getRandomInt(0,r.audioClips.length-1);
    // get an asset by id
    var asset = r.audioClips[randomIndex].id;
    // add a slot
    soundEntity.sound.addSlot('sound', {
        asset: asset
    });
    soundEntity.sound.play('sound');
    }
};

EventResponse.prototype.perform_Reload_Parent_Page = function(r)
{
    window.parent.location.reload();
};

EventResponse.prototype.URL_Fallback = function(dest,newTab) 
{
    console.log("Setting fallback URL: " + dest);
    if(newTab)
    {
        setTimeout(_ => window.open(dest,'_blank'), 1000);
    }
    else
    {
        setTimeout(_ => window.location = dest, 1000);
    }
    
};

EventResponse.prototype.isIOS = function() 
{
    return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
};

EventResponse.prototype.isAndroid = function() 
{
    return (navigator.userAgent.toLowerCase().indexOf("android") > -1);
};

EventResponse.prototype.perform_Screen_Shake = function(r) {
    this.ScreenShake = 
    {
        r : r,
        power: r.shakePower,
        remaining: r.shakeDuration,
        canvas: document.querySelector('canvas'),
        body: document.querySelector('body'),
    };
};

EventResponse.prototype.perform_Screen_Shake_Update = function(dt) {
    if(this.ScreenShake.r.setPageBackgroundColor)
    {
        this.ScreenShake.body.style = 'background-color: ' + this.ScreenShake.r.documentPageColor + ';';
    }
    
    if(this.ScreenShake.remaining <= 0)
    {
        this.ScreenShake.remaining = 0;
        this.ScreenShake.canvas.style.transform = "";
        this.ScreenShake.canvas.style.backgroundColor = "";
        this.ScreenShake = null; // <--- suicide  
    }
    else
    {
        this.ScreenShake.remaining -= dt;
        
        let shakePercent = this.ScreenShake.power  * (this.ScreenShake.remaining / 0.5) * this.ScreenShake.r.shakeMaxPercent; 
        
        var angle = Math.random() * 2 * Math.PI;

        var distPx = shakePercent / 100 * pc.app.graphicsDevice.height;
        
        var x = distPx * Math.sin(angle);
        var y = distPx * Math.cos(angle);

        if(Math.floor(Math.abs(x)) < 1 && Math.floor(Math.abs(y)) < 1)
        {
            if(Math.abs(x) > Math.abs(y))
            {
                x = x > 0 ? 1 : -1;
                y = 0;
            }
            else
            {
                x = 0;
                y = y > 0 ? 1 : -1;
            }
        }
        
        this.ScreenShake.canvas.style =  "transform: translate("+x+"px, "+y+"px);";
    }
};

EventResponse.prototype.perform_Select_Random_String = function(r) 
{
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.element.text);
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
           if (r.randomString.length > 0)
           {
               entToEffectNotNull[i].element.text = r.randomString[this.getRandomInt(0,r.randomString.length-1)];
           }
        } 
    }
    else if (this.entity.element.text)
    {
        if (r.randomString.length > 0)
        {
               this.entity.element.element.text = r.randomString[this.getRandomInt(0,r.randomString.length-1)];
        }
    }

};

EventResponse.prototype.perform_Set_Animation_State = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.animation);

    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            if (r.play && r.animationName !== '')
            {
                entToEffectNotNull[i].animation.play(r.animationName,r.blendTime); 
            }
            entToEffectNotNull[i].animation.loop = r.loopAnim;
        } 
    }
};

EventResponse.prototype.perform_Set_Anim_Graph_State = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.anim);
    console.log(entToEffectNotNull.length);
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            if (r.animationName !== "")
            {
                entToEffectNotNull[i].anim.baseLayer.transition(r.animationName, r.animTransTime, r.animOffset);
            }
        } 
    }
    else if (this.entity.anim)
    {
        if (r.animationName !== "")
        {
            this.entity.anim.baseLayer.transition(r.animationName, r.animTransTime, r.animOffset);
        }
    }
};

EventResponse.prototype.perform_Set_Anim_State = function(r) {
};

EventResponse.prototype.perform_Set_Anim_Trigger = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.anim);
    
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            entToEffectNotNull[i].anim.setTrigger(r.animationTrigger);
        } 
    }
    else if (this.entity.anim)
    {
        this.entity.anim.setTrigger(r.animationTrigger);
    }
};

EventResponse.prototype.perform_Set_Anim_Integer = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.anim);
    
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            entToEffectNotNull[i].anim.setInteger(r.animationIntegerName, r.animationValue);
        } 
    }
    else if (this.entity.anim)
    {
        this.entity.anim.setInteger(r.animationIntegerName, r.animationValue);
    }
};

EventResponse.prototype.perform_Set_Anim_Boolean = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.anim);
    
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            entToEffectNotNull[i].anim.setBoolean(r.animationBooleanName, r.animationBoolean);
        } 
    }
    else if (this.entity.anim)
    {
        this.entity.anim.setBoolean(r.animationBooleanName, r.animationBoolean);
    }
};

EventResponse.prototype.perform_Set_Anim_Float = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.anim);
    
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            entToEffectNotNull[i].anim.setFloat(r.animationFloatName, r.animationValue);
        } 
    }
    else if (this.entity.anim)
    {
        this.entity.anim.setFloat(r.animationFloatName, r.animationValue);
    }
};

EventResponse.prototype.perform_Set_Audio_State = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.sound);
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            switch(r.soundState) {
                case 'play':
                    entToEffectNotNull[i].sound.play(r.soundSlot);
                    break;
                case 'pause':
                    entToEffectNotNull[i].sound.pause(r.soundSlot);
                    break;
                case 'resume':
                    entToEffectNotNull[i].sound.resume(r.soundSlot);
                    break;
                case 'stop':
                    entToEffectNotNull[i].sound.stop(r.soundSlot);
                    break;
                default:
                    break;
            }
            if (r.randomPitch)
            {
                entToEffectNotNull[i].sound.pitch = Math.random() * (r.maxPitch - r.minPitch) + r.minPitch;
            }
        } 
    }  
};

EventResponse.prototype.perform_Set_Audio_Volume = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.sound);
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            if (entToEffectNotNull[i] !== null && entToEffectNotNull[i].sound){
                entToEffectNotNull[i].sound.volume = r.volume;
            }
        }
    }
    else if (this.entity.sound)
    {
        this.entity.sound.volume = r.volume;
    }
};

EventResponse.prototype.perform_Set_Custom_Float = function(r) {
    // customFloatPropertyPath
    let PropertyPathParts = r.customFloatPropertyPath.split(".");
    if (PropertyPathParts === null) return; // exists
    if (!Array.isArray(PropertyPathParts)) return; // is array
    if (PropertyPathParts.length < 1) return; // array has an element
    if (PropertyPathParts[0] === "") return; // element has value
    let entToEffectNotNull = r.entitiesToEffect.filter(e => e);
    
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            let descProp = this.getDescendantProp(entToEffectNotNull[i], r.customFloatPropertyPath);
            if (typeof(descProp) !== 'number') return; // check the path is pointing at a number   
            this.setDescendantProp(entToEffectNotNull[i], r.customFloatPropertyPath, r.floatValue);
        }
    }
    else
    {
        let descProp = this.getDescendantProp(this.entity, r.customFloatPropertyPath);
        if (typeof(descProp) !== 'number') return; // check the path is pointing at a number   
        this.setDescendantProp(this.entity, r.customFloatPropertyPath, r.floatValue);
    }
};

EventResponse.prototype.perform_Set_Custom_Vec3 = function(r) {
};

EventResponse.prototype.perform_Set_Entity_Enable = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e);
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
           setEnableState(r,entToEffectNotNull[i]);
        }
    }
    else
    {
        setEnableState(r,this.entity);
    }

    function setEnableState(r,ent) 
    {
         switch (r.newEnabledState)
            {
                case 1:
                    ent.enabled = true;
                    break;
                case 2:
                    ent.enabled = false;
                    break;
                case 3:
                    ent.enabled = !(ent.enabled);
                    break;
                default:
            }
    }
};

EventResponse.prototype.perform_Set_Material = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.model || e.render);
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            if(entToEffectNotNull[i].model)
            {
                if(r.materialName != "")
                {
                    entToEffectNotNull[i].model.meshInstances.forEach( function(meshInstance){
                    if (meshInstance.material.name == r.materialName)
                    {
                        meshInstance.material = r.material.resource;
                        meshInstance.material.update();
                    } 
                    });
                }
                else
                {
                    entToEffectNotNull[i].model.meshInstances.forEach( function(meshInstance){
                        meshInstance.material = r.material.resource;
                        meshInstance.material.update();
                    });
                }
            }
            else if(entToEffectNotNull[i].render)
            {
                if(r.materialName != "")
                {
                    entToEffectNotNull[i].render.meshInstances.forEach( function(meshInstance){
                    if (meshInstance.material.name == r.materialName)
                    {
                        meshInstance.material = r.material.resource;
                        meshInstance.material.update();
                    } 
                });
                }
                else
                {
                entToEffectNotNull[i].render.meshInstances.forEach( function(meshInstance){
                    meshInstance.material = r.material.resource;
                    meshInstance.material.update();
                });
                }
            }

         }
    }
};

EventResponse.prototype.perform_Set_Material_Colour = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.model || e.render);
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            if (entToEffectNotNull[i].model)
            {
                entToEffectNotNull[i].model.meshInstances.forEach( function(meshInstance){
                    meshInstance.material[r.colourProperty] = r.materialColour; 
                    meshInstance.material.update();
                });
            }
            else if (entToEffectNotNull[i].render)
            {
                entToEffectNotNull[i].render.meshInstances.forEach( function(meshInstance){
                    meshInstance.material[r.colourProperty] = r.materialColour;
                    meshInstance.material.update();
                });
            }

        }
    }
};

EventResponse.prototype.perform_Set_Material_Float = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.model || e.render);
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            if (entToEffectNotNull[i].model)
            {
                entToEffectNotNull[i].model.meshInstances.forEach( function(meshInstance){
                    meshInstance.material[r.floatProperty] = r.floatValue;
                    meshInstance.material.update();
                });
            }
            else if (entToEffectNotNull[i].render)
            {
                entToEffectNotNull[i].render.meshInstances.forEach( function(meshInstance){
                    meshInstance.material[r.floatProperty] = r.floatValue;
                    meshInstance.material.update();
                });
            }

        }
    }
};

EventResponse.prototype.perform_Set_Material_Parameter = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.model || e.render);
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            if (entToEffectNotNull[i].model)
            {
                entToEffectNotNull[i].model.meshInstances.forEach( function(meshInstance){
                    console.log(r.materialParameter,r.floatValue);
                    meshInstance.material.setParameter(r.materialParameter,r.floatValue);
                });
            }
            else if (entToEffectNotNull[i].render)
            {
                entToEffectNotNull[i].render.meshInstances.forEach( function(meshInstance){
                    meshInstance.material.setParameter(r.materialParameter,r.floatValue);
                });
            }

        }
    }
};

EventResponse.prototype.perform_Set_Material_Texture = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.model || e.render);
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
            if (entToEffectNotNull[i].model)
            {
                entToEffectNotNull[i].model.meshInstances.forEach( function(meshInstance){
                    meshInstance.material[r.textureProperty] = r.textureMap.resource;
                    meshInstance.material.update();
                });
            }
            else if (entToEffectNotNull[i].render)
            {
                entToEffectNotNull[i].render.meshInstances.forEach( function(meshInstance){
                    meshInstance.material[r.textureProperty] = r.textureMap.resource;
                    meshInstance.material.update();
                });
            }

        }
    }
};

EventResponse.prototype.perform_Set_Parent = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e);
    if (entToEffectNotNull.length > 0)
    {
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
           setParent(r,entToEffectNotNull[i]);
        }
    }
    else
    {
        setParent(r,this.entity);
    }

    function setParent(r,ent)
    {
        var pos = ent.getPosition().clone();
        var rot = ent.getRotation().clone();
        var scale = ent.getLocalScale().clone();

        if (r.parentEntity !== null && ent !== null)
        {
            ent.reparent(r.parentEntity);
        }
        else
        {
            ent.reparent(pc.app.root);
        }

        if (r.keepPos)
        {
            ent.setPosition(pos);
            ent.setRotation(rot);
            ent.setLocalScale(scale);
        }  
    }
};

EventResponse.prototype.perform_Set_Particle_State = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.particlesystem);

    
    if (entToEffectNotNull.length > 0)
    {
        console.log("debug: " + r.screenSpace);
        for (var i=0; i<entToEffectNotNull.length; i++)
        {
             setParticleState(r,entToEffectNotNull[i]);  
        } 
    }
    else if (this.entity.particlesystem)
    {
        setParticleState(r,this.entity);
    }

    function setParticleState(r, ent)
    {
       
        ent.particlesystem.screenSpace = r.screenSpace;
        switch(r.particleState) {
                case 'play':
                    ent.particlesystem.play();
                    break;
                case 'pause':
                    ent.particlesystem.pause();
                    break;
                case 'unpause':
                    ent.particlesystem.unpause();
                    break;
                case 'stop':
                    ent.particlesystem.stop();
                    break;
                case 'togglePaused':
                    if(ent.particlesystem.data.paused)
                    {
                        ent.particlesystem.unpause();
                    }
                    else
                    {
                        ent.particlesystem.pause();
                    }
                    break;
                case 'reset':
                    ent.particlesystem.reset();
                    break;
                default:
                    break;
            }   
    }
};

EventResponse.prototype.perform_Set_Transform = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e);
    
    if (entToEffectNotNull.length > 0) {
        for (var i = 0; i < entToEffectNotNull.length; i++) {
            setTransform(r,entToEffectNotNull[i]);
        }
    } 
    else 
    {
        setTransform(r,this.entity);
    }

    function setTransform(r, ent) {
        if (r.space == 'world') {
            if (r.setPosition) 
            {
                if (r.additivePos)
                {
                    var pos = ent.getPosition().clone().add(r.position);
                    ent.setPosition(pos);
                }
                else
                {
                    ent.setPosition(r.position);
                } 
            }

            if (r.setRotation) 
            {
                if (r.additiveRot)
                {
                    var rot = ent.getEulerAngles().clone().add(r.rotation);
                    ent.setEulerAngles(rot);
                }
                else
                {
                    ent.setEulerAngles(r.rotation);
                }
            }
        }
        else 
        {
            if (r.setPosition) 
            {
                if (r.additivePos)
                {
                    var localPos = ent.getLocalPosition().clone.add(r.position);
                    ent.setLocalPosition(localPos);
                }
                else
                {
                    ent.setLocalPosition(r.position);
                } 
            }

            if (r.setRotation) 
            {
                if (r.additiveRot)
                {
                    var localRot = ent.getLocalEulerAngles().clone.add(r.rotation);
                    ent.setEulerAngles(localRot);
                }
                else
                {
                    ent.setLocalEulerAngles(r.rotation);
                }
            }
        }
        if (r.setScale) 
        {
            if (r.additiveScale)
            {
                var scale = ent.getLocalScale.clone.add(r.scale);
                ent.setLocalScale(scale);
            }
            else
            {
                ent.setLocalScale(r.scale);
            }
        }
    }
};

EventResponse.prototype.perform_Set_Tween_State = function(r) {
};

EventResponse.prototype.perform_Set_Zebrar_Middleware = function(r) {
    if(ZebrarMiddleware)
    {
       switch(r.zebrarState) {
                case 'gameLoaded':
                    ZebrarMiddleware.sendGameLoaded();
                    break;
                case 'gameFinished':
                    ZebrarMiddleware.sendGameFinished();
                    break;
                default:
                    break;
            }     
    }
};

EventResponse.prototype.perform_Share_URL = function(r) {
    
    var shareData = {};
    if (r.enableURL)
    {
        shareData.url = r.URL;
    }

    if (r.enableShareText)
    {
        shareData.text = r.shareText;
    }

    if (r.enableShareTitle)
    {
        shareData.title = r.shareTitle;
    }

    if(Object.keys(shareData).length != 0)
    {
        this.ShareData(shareData);
    }
    
};

EventResponse.prototype.perform_Typing_Transition = function(r) {
    
    var textEntity;
    var i = 0;
    var txt = r.typingString;
    var speed = r.typingSpeed;
    
    if (r.entityToEffect != null && r.entityToEffect.element.text)
    {
        textEntity = r.entityToEffect;
        textEntity.element.text = "";
        typeWriter();
    }
    else if (this.entity.element.text)
    {
        textEntity = this.entity;
        textEntity.element.text = "";
        typeWriter();
    }
    
    function typeWriter() {
        if (i < txt.length) 
        {
            textEntity.element.text += txt.charAt(i);
            i++;
            setTimeout(typeWriter, speed);
        }
    }

};

EventResponse.prototype.perform_Tween_Audio_Volume = function(r) {
    if (this.tweens.volume) {
        this.tweens.volume.stop();
    }
    
    let entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.sound);
    let numEnt = entToEffectNotNull.length;
    if (numEnt > 0)        
    {
        this.tweenVolume = {};

        var to = (r.useTo === true) ? r.volumeTo : entToEffectNotNull[0].sound.volume;
        if (r.useFrom) 
        {
            this.tweenVolume.value = r.volumeFrom;
            entToEffectNotNull[0].sound.volume = r.volumeFrom;
            for (var i = 0; i<numEnt; i++)
            {
                entToEffectNotNull[i].sound.volume = r.volumeFrom; 
            }
        }
        else
        {
            this.tweenVolume.value = entToEffectNotNull[0].sound.volume;
        }
        
        // create a new tween using our script attributes
        this.tweens.volume = this.app.tween(this.tweenVolume)
            .to({value: to}, r.duration, pc[r.easing])
            .delay(r.delay)
            .loop(r.loopTween)
            .yoyo(r.yoyo);
        
        var updateFunction = (r.usePostUpdate) ? 'postUpdate' : 'update';
        this.tweens.volume.on(updateFunction, function () 
        {      
            for (var i = 0; i<numEnt; i++)
            {
                entToEffectNotNull[i].sound.volume = this.tweenVolume.value; 
            }
        }.bind(this));

        // only set repeats if loopTween is false
        if (!r.loopTween)
            this.tweens.volume.repeat(r.repeat);

        // start the tween
        this.tweens.volume.start();
    }
};

EventResponse.prototype.perform_Tween_Custom_Float = function(r) {
    // if we are already tweening then stop first
    if (this.tweens.customFloat) 
    {
        this.tweens.customFloat.stop();
    }
    
    // customFloatPropertyPath
    let PropertyPathParts = r.customFloatPropertyPath.split(".");
    if (PropertyPathParts === null) return; // exists
    if (!Array.isArray(PropertyPathParts)) return; // is array
    if (PropertyPathParts.length < 1) return; // array has an element
    if (PropertyPathParts[0] === "") return; // element has value
    let entToEffectNotNull = r.entitiesToEffect.filter(e => e);

    let numEnt = entToEffectNotNull.length;
    if (numEnt > 0) 
    {
        let descProp = this.getDescendantProp(entToEffectNotNull[0], r.customFloatPropertyPath);
        if (typeof(descProp) !== 'number') return; // check the path is pointing at a number
        
        this.tweenCustomFloat = {};
        var to = (r.useTo === true) ? r.animateToNumber : descProp;
        if (r.useFrom) 
        {
            this.tweenCustomFloat.value = r.animateFromNumber;
            for (var i = 0; i<numEnt; i++)
            {
                this.setDescendantProp(entToEffectNotNull[i], r.customFloatPropertyPath, r.animateFromNumber);
            }
        }
        else
        {
            this.tweenCustomFloat.value = descProp;
        }
        
        this.tweens.customFloat = this.app.tween(this.tweenCustomFloat)
            .to({value: to}, r.duration, pc[r.easing])
            .delay(r.delay)
            .loop(r.loopTween)
            .yoyo(r.yoyo);
        
        this.tweens.customFloat.on('update', function () 
        {
            for (var i = 0; i<numEnt; i++)
            {
                this.setDescendantProp(entToEffectNotNull[i], r.customFloatPropertyPath, this.tweenCustomFloat.value);
            }
        }.bind(this));
        // only set repeats if loopTween is false
        if (!r.loopTween)
            this.tweens.customFloat.repeat(r.repeat);

        // start the tween
        this.tweens.customFloat.start();
    }
};

EventResponse.prototype.perform_Tween_Custom_Vec3 = function(r) {
};

EventResponse.prototype.perform_Tween_Material_Colour = function(r) {
    // if we are already tweening then stop first
    if (this.tweens.matColour) 
    {
        this.tweens.matColour.stop();
    }
    
    let entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.model);

    let numEnt = entToEffectNotNull.length;
    if (numEnt > 0) 
    {
        this.tweenMatColour = {};

        var to = (r.useTo === true) ? r.animateToRGB : entToEffectNotNull[0].model.meshInstances[0].material[r.colourProperty];
        if (r.useFrom) 
        {
            this.tweenMatColour.value = r.animateFromRGB;
            entToEffectNotNull[0].model.meshInstances.forEach(mi =>
            {
                mi.material[r.colourProperty] = r.animateFromRGB;
                mi.material.update();
            });
            for (var i = 0; i<numEnt; i++)
            {
                entToEffectNotNull[i].model.meshInstances.forEach(mi =>
                {
                    mi.material[r.colourProperty] = r.animateFromRGB;
                    mi.material.update();
                });
            }
        }
        else
        {
            this.tweenMatColour.value = entToEffectNotNull[0].model.meshInstances[0].material[r.colourProperty];
        }
        
        this.tweens.matColour = this.app.tween(this.tweenMatColour.value)
            .to(to, r.duration, pc[r.easing])
            .delay(r.delay)
            .loop(r.loopTween)
            .yoyo(r.yoyo);
    
        this.tweens.matColour.on('update', function () 
        {
            for (var i = 0; i<numEnt; i++)
            {
                if (entToEffectNotNull[i].model)
                {
                    entToEffectNotNull[i].model.meshInstances.forEach(mi =>
                    {
                        mi.material[r.colourProperty] = this.tweenMatColour.value;
                        mi.material.update();
                    });
                }
            }
        }.bind(this));
        
        // only set repeats if loopTween is false
        if (!r.loopTween)
            this.tweens.matColour.repeat(r.repeat);

        // start the tween
        this.tweens.matColour.start();        
    }    
};

EventResponse.prototype.perform_Tween_Material_Float = function(r) {
    // if we are already tweening then stop first
    if (this.tweens.matNumber) 
    {
        this.tweens.matNumber.stop();
    }
    
    let entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.model || e.render);

    let numEnt = entToEffectNotNull.length;
    if (numEnt > 0) 
    {
        this.tweenMatNumber = {};
        var to;
    
        if (entToEffectNotNull[0].model)
        {
            to = (r.useTo === true) ? r.animateToNumber : entToEffectNotNull[0].model.meshInstances[0].material[r.floatProperty];
        }
        else if (entToEffectNotNull[0].render)
        {
            to = (r.useTo === true) ? r.animateToNumber : entToEffectNotNull[0].render.meshInstances[0].material[r.floatProperty];
        }
        
        if (r.useFrom) 
        {
            this.tweenMatNumber.value = r.animateFromNumber;
            
            if (entToEffectNotNull[0].model)
            {
                entToEffectNotNull[0].model.meshInstances.forEach(mi => { mi.material[r.floatProperty] = r.animateFromNumber; mi.material.update();});
            }
            else if (entToEffectNotNull[0].render)
            {
                entToEffectNotNull[0].render.meshInstances.forEach(mi => { mi.material[r.floatProperty] = r.animateFromNumber; mi.material.update();});
            }
            
            for (var i = 0; i<numEnt; i++)
            {
                if (entToEffectNotNull[i].model)
                {
                    entToEffectNotNull[i].model.meshInstances.forEach(mi => { mi.material[r.floatProperty] = r.animateFromNumber; mi.material.update();});
                }
                else if (entToEffectNotNull[i].render)
                {
                    entToEffectNotNull[i].render.meshInstances.forEach(mi => { mi.material[r.floatProperty] = r.animateFromNumber; mi.material.update();});
                }
                
            }
        }
        else
        {
            if (entToEffectNotNull[0].model)
            {
                this.tweenMatNumber.value = entToEffectNotNull[0].model.meshInstances[0].material[r.floatProperty];
            }
            else if (entToEffectNotNull[0].render)
            {
                this.tweenMatNumber.value = entToEffectNotNull[0].render.meshInstances[0].material[r.floatProperty];
            }
        }
        
        this.tweens.matNumber = this.app.tween(this.tweenMatNumber)
            .to({value: to}, r.duration, pc[r.easing])
            .delay(r.delay)
            .loop(r.loopTween)
            .yoyo(r.yoyo);
        
        this.tweens.matNumber.on('update', function () 
        {
            for (var i = 0; i<numEnt; i++)
            {
                if (entToEffectNotNull[i].model)
                {
                    entToEffectNotNull[i].model.meshInstances.forEach(mi => { mi.material[r.floatProperty] = this.tweenMatNumber.value; mi.material.update();});
                }

                if (entToEffectNotNull[i].render)
                {
                    entToEffectNotNull[i].render.meshInstances.forEach(mi => { mi.material[r.floatProperty] = this.tweenMatNumber.value; mi.material.update();});
                }
            }
        }.bind(this));
        // only set repeats if loopTween is false
        if (!r.loopTween)
            this.tweens.matNumber.repeat(r.repeat);

        // start the tween
        this.tweens.matNumber.start();        
    }
};

EventResponse.prototype.perform_Tween_Material_Offset = function(r) {
    // if we are already tweening then stop first
    if (this.tweens.matOffset) 
    {
        this.tweens.matOffset.stop();
    }
    
    let entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.model || e.render);

    let numEnt = entToEffectNotNull.length;
    if (numEnt > 0) 
    {
        this.tweenMatOffset = {};
        var to = r.animateToVec2.clone();
        this.tweenMatOffset.value = r.animateFromVec2.clone();
        this.tweens.matOffset = this.app.tween(this.tweenMatOffset.value)
            .to(to, r.duration, pc[r.easing])
            .delay(r.delay)
            .loop(r.loopTween)
            .yoyo(r.yoyo);
        
        this.tweens.matOffset.on('update', function () 
        {
            for (var i = 0; i<numEnt; i++)
            {
                if (entToEffectNotNull[i].model)
                {
                    entToEffectNotNull[i].model.meshInstances.forEach(mi => {
                    if (r.aoMap) mi.material.aoMapOffset = this.tweenMatOffset.value;
                    if (r.clearCoatGlossMap) mi.material.clearCoatGlossMapOffset = this.tweenMatOffset.value;
                    if (r.clearCoatMap) mi.material.clearCoatMapOffset = this.tweenMatOffset.value;
                    if (r.clearCoatNormalMap) mi.material.clearCoatNormalMapOffset = this.tweenMatOffset.value;
                    if (r.clearCoatDetailMap) mi.material.clearCoatDetailMapOffset = this.tweenMatOffset.value;
                    if (r.diffuseMap) mi.material.diffuseMapOffset = this.tweenMatOffset.value;
                    if (r.emissiveMap) mi.material.emissiveMapOffset = this.tweenMatOffset.value;
                    if (r.glossMap) mi.material.glossMapOffset = this.tweenMatOffset.value;
                    if (r.heightMap) mi.material.heightMapOffset = this.tweenMatOffset.value;
                    if (r.lightMap) mi.material.lightMapOffset = this.tweenMatOffset.value;
                    if (r.metalnessMap) mi.material.metalnessMapOffset = this.tweenMatOffset.value;
                    if (r.normalDetailMap) mi.material.normalDetailMapOffset = this.tweenMatOffset.value;
                    if (r.normalMap) mi.material.normalMapOffset = this.tweenMatOffset.value;
                    if (r.opacityMap) mi.material.opacityMapOffset = this.tweenMatOffset.value;
                    if (r.specularMap) mi.material.specularMapOffset = this.tweenMatOffset.value; 
                    mi.material.update();});
                }

                if (entToEffectNotNull[i].render)
                {
                    entToEffectNotNull[i].render.meshInstances.forEach(mi => {
                    if (r.aoMap) mi.material.aoMapOffset = this.tweenMatOffset.value;
                    if (r.clearCoatGlossMap) mi.material.clearCoatGlossMapOffset = this.tweenMatOffset.value;
                    if (r.clearCoatMap) mi.material.clearCoatMapOffset = this.tweenMatOffset.value;
                    if (r.clearCoatNormalMap) mi.material.clearCoatNormalMapOffset = this.tweenMatOffset.value;
                    if (r.clearCoatDetailMap) mi.material.clearCoatDetailMapOffset = this.tweenMatOffset.value;
                    if (r.diffuseMap) mi.material.diffuseMapOffset = this.tweenMatOffset.value;
                    if (r.emissiveMap) mi.material.emissiveMapOffset = this.tweenMatOffset.value;
                    if (r.glossMap) mi.material.glossMapOffset = this.tweenMatOffset.value;
                    if (r.heightMap) mi.material.heightMapOffset = this.tweenMatOffset.value;
                    if (r.lightMap) mi.material.lightMapOffset = this.tweenMatOffset.value;
                    if (r.metalnessMap) mi.material.metalnessMapOffset = this.tweenMatOffset.value;
                    if (r.normalDetailMap) mi.material.normalDetailMapOffset = this.tweenMatOffset.value;
                    if (r.normalMap) mi.material.normalMapOffset = this.tweenMatOffset.value;
                    if (r.opacityMap) mi.material.opacityMapOffset = this.tweenMatOffset.value;
                    if (r.specularMap) mi.material.specularMapOffset = this.tweenMatOffset.value; 
                    mi.material.update();});
                }
            }
        }.bind(this));
        // only set repeats if loopTween is false
        if (!r.loopTween)
            this.tweens.matOffset.repeat(r.repeat);

        // start the tween
        this.tweens.matOffset.start();        
    }
};

EventResponse.prototype.perform_Tween_Material_Tiling = function(r) {
    // if we are already tweening then stop first
    if (this.tweens.matTiling) 
    {
        this.tweens.matTiling.stop();
    }
    
    let entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.model || e.render);

    let numEnt = entToEffectNotNull.length;
    if (numEnt > 0) 
    {
        this.tweenMatTiling = {};
        var to = r.animateToVec2.clone(); 
        this.tweenMatTiling.value = r.animateFromVec2.clone();
        this.tweens.matTiling = this.app.tween(this.tweenMatTiling.value)
            .to(to, r.duration, pc[r.easing])
            .delay(r.delay)
            .loop(r.loopTween)
            .yoyo(r.yoyo);
        
        this.tweens.matTiling.on('update', function () 
        {
            for (var i = 0; i<numEnt; i++)
            {
                if (entToEffectNotNull[i].model)
                {
                    entToEffectNotNull[i].model.meshInstances.forEach(mi => { 
                    if (r.aoMap) mi.material.aoMapTiling = this.tweenMatTiling.value;
                    if (r.clearCoatGlossMap) mi.material.clearCoatGlossMapTiling = this.tweenMatTiling.value;
                    if (r.clearCoatMap) mi.material.clearCoatMapTiling = this.tweenMatTiling.value;
                    if (r.clearCoatNormalMap) mi.material.clearCoatNormalMapTiling = this.tweenMatTiling.value;
                    if (r.clearCoatDetailMap) mi.material.clearCoatDetailMapTiling = this.tweenMatTiling.value;
                    if (r.diffuseMap) mi.material.diffuseMapTiling = this.tweenMatTiling.value;
                    if (r.emissiveMap) mi.material.emissiveMapTiling = this.tweenMatTiling.value;
                    if (r.glossMap) mi.material.glossMapTiling = this.tweenMatTiling.value;
                    if (r.heightMap) mi.material.heightMapTiling = this.tweenMatTiling.value;
                    if (r.lightMap) mi.material.lightMapTiling = this.tweenMatTiling.value;
                    if (r.metalnessMap) mi.material.metalnessMapTiling = this.tweenMatTiling.value;
                    if (r.normalDetailMap) mi.material.normalDetailMapTiling = this.tweenMatTiling.value;
                    if (r.normalMap) mi.material.normalMapTiling = this.tweenMatTiling.value;
                    if (r.opacityMap) mi.material.opacityMapTiling = this.tweenMatTiling.value;
                    if (r.specularMap) mi.material.specularMapTiling = this.tweenMatTiling.value;
                    mi.material.update();});
                }

                if (entToEffectNotNull[i].render)
                {
                    entToEffectNotNull[i].render.meshInstances.forEach(mi => { 
                    if (r.aoMap) mi.material.aoMapTiling = this.tweenMatTiling.value;
                    if (r.clearCoatGlossMap) mi.material.clearCoatGlossMapTiling = this.tweenMatTiling.value;
                    if (r.clearCoatMap) mi.material.clearCoatMapTiling = this.tweenMatTiling.value;
                    if (r.clearCoatNormalMap) mi.material.clearCoatNormalMapTiling = this.tweenMatTiling.value;
                    if (r.clearCoatDetailMap) mi.material.clearCoatDetailMapTiling = this.tweenMatTiling.value;
                    if (r.diffuseMap) mi.material.diffuseMapTiling = this.tweenMatTiling.value;
                    if (r.emissiveMap) mi.material.emissiveMapTiling = this.tweenMatTiling.value;
                    if (r.glossMap) mi.material.glossMapTiling = this.tweenMatTiling.value;
                    if (r.heightMap) mi.material.heightMapTiling = this.tweenMatTiling.value;
                    if (r.lightMap) mi.material.lightMapTiling = this.tweenMatTiling.value;
                    if (r.metalnessMap) mi.material.metalnessMapTiling = this.tweenMatTiling.value;
                    if (r.normalDetailMap) mi.material.normalDetailMapTiling = this.tweenMatTiling.value;
                    if (r.normalMap) mi.material.normalMapTiling = this.tweenMatTiling.value;
                    if (r.opacityMap) mi.material.opacityMapTiling = this.tweenMatTiling.value;
                    if (r.specularMap) mi.material.specularMapTiling = this.tweenMatTiling.value;
                    mi.material.update();});
                }
            }
        }.bind(this));
        // only set repeats if loopTween is false
        if (!r.loopTween)
            this.tweens.matTiling.repeat(r.repeat);

        // start the tween
        this.tweens.matTiling.start();        
    }
};

EventResponse.prototype.perform_Tween_Position = function(r) {
    
    // if we are already tweening then stop first
    if (this.tweens.position) 
    {
        this.tweens.position.stop();
    }
    
    let entToEffectNotNull = r.entitiesToEffect.filter(e => e);

    let numEnt = entToEffectNotNull.length;
    if (numEnt > 0)        
    {
        var to;
        if (r.useTo) 
        {
            if (r.animateToEnt)
            {
                to = r.animateToEnt.getPosition().clone();
            }
            else
            {
                if (r.relativePos)
                {
                    to = entToEffectNotNull[0].getPosition().clone().add(r.animateToVec3.clone());
                }
                else
                {
                    to = r.animateToVec3.clone(); // shallow copy
                }   
            }
            
        }
        else
        {
            if (r.space === 'local')
            {
                to = entToEffectNotNull[0].getLocalPosition().clone(); // shallow copy
            }
            else
            {
                to = entToEffectNotNull[0].getPosition().clone(); // shallow copy
            }
        }
        
        // create an object for the tweening library to play with
        if (r.useFrom) 
        {
            if (r.animateFromEnt)
            {
                this.tweenData.position = r.animateFromEnt.getPosition();
            }
            else
            {
                if (r.relativePos)
                {
                    this.tweenData.position = entToEffectNotNull[0].getPosition().clone().add(r.animateFromVec3.clone());
                }
                else
                {
                    this.tweenData.position = r.animateFromVec3.clone(); // shallow copy
                }
            }
            
            // when using 'From' set the intial position in case user is using delay option
            for (var i = 0; i<numEnt; i++)
            {
                if (r.space === 'local')
                {
                    entToEffectNotNull[i].setLocalPosition(this.tweenData.position); 
                }
                else
                {
                    entToEffectNotNull[i].setPosition(this.tweenData.position);
                }
            }
        }
        else
        {
            if (r.space === 'local')
            {
                this.tweenData.position = entToEffectNotNull[0].getLocalPosition().clone(); // shallow copy 
            }
            else
            {
                this.tweenData.position = entToEffectNotNull[0].getPosition().clone(); // shallow copy
            }
        }

        // create a new tween using our script attributes
        this.tweens.position = this.app.tween(this.tweenData.position)
            .to(to, r.duration, pc[r.easing])
            .delay(r.delay)
            .loop(r.loopTween)
            .yoyo(r.yoyo);
        

        this.tweens.position.on('update', function () 
        {
            for (var i = 0; i<numEnt; i++)
            {
                if (r.space === 'local')
                {
                    entToEffectNotNull[i].setLocalPosition(this.tweenData.position); 
                }
                else
                {
                    entToEffectNotNull[i].setPosition(this.tweenData.position);
                }
            }
        }.bind(this));
        
        // only set repeats if loopTween is false
        if (!r.loopTween)
            this.tweens.position.repeat(r.repeat);

        // start the tween
        this.tweens.position.start();
    }

};

EventResponse.prototype.perform_Tween_Rotation = function(r) {
     // if we are already tweening then stop first
    if (this.tweens.rotation) {
        //console.log('Stopping existing tween.');
        this.tweens.rotation.stop();
    }
    
    let entToEffectNotNull = r.entitiesToEffect.filter(e => e);
    let numEnt = entToEffectNotNull.length;
    if (numEnt > 0)        
    {
        var to;// = (r.useTo === true) ? r.animateToVec3 : entPos;
        if (r.useTo) 
        {
            if (r.animateToEnt)
            {
                to = r.animateToEnt.getLocalEulerAngles().clone();
            }
            else
            {
                if (r.relativeRot)
                {
                    to = entToEffectNotNull[0].getLocalEulerAngles().clone().add(r.animateToVec3.clone());
                }
                else
                {
                    to = r.animateToVec3.clone(); // shallow copy
                }   
            }
        }
        else
        {
            if (r.space === 'local')
            {
                to = entToEffectNotNull[0].getLocalEulerAngles().clone();
            }
            else
            {
                to = entToEffectNotNull[0].getEulerAngles().clone();
            }
        }
        
        if (r.useFrom) 
        {
            if (r.animateFromEnt)
            {
                this.tweenData.rotation = r.animateFromEnt.getLocalEulerAngles();
            }
            else
            {
                if(r.relativeRot)
                {
                    this.tweenData.rotation = entToEffectNotNull[0].getLocalEulerAngles().clone().add(r.animateFromVec3.clone());
                }
                else
                {
                    this.tweenData.rotation = r.animateFromVec3.clone(); // shallow copy
                }
            }
            for (var i = 0; i<numEnt; i++)
            {
                if (r.space === 'local')
                {
                    //console.log('Local -> this.tweenData.rotation: ' + this.tweenData.rotation);
                    entToEffectNotNull[i].setLocalEulerAngles(this.tweenData.rotation);
                }
                else
                {
                    //console.log('World -> this.tweenData.rotation: ' + this.tweenData.rotation);
                    entToEffectNotNull[i].setEulerAngles(this.tweenData.rotation);  
                }
            }
        }
        else
        {
            if (r.space === 'local')
            {
                this.tweenData.rotation = entToEffectNotNull[0].getLocalEulerAngles().clone();
            }
            else
            {
                this.tweenData.rotation = entToEffectNotNull[0].getEulerAngles().clone();
            }
        }

        // create a new tween using our script attributes
        this.tweens.rotation = this.app.tween(this.tweenData.rotation)
            .rotate(to, r.duration, pc[r.easing])
            .delay(r.delay)
            .loop(r.loopTween)
            .yoyo(r.yoyo); 
        
        var updateFunction = (r.usePostUpdate) ? 'postUpdate' : 'update';
        this.tweens.rotation.on(updateFunction, function () 
        {      
            for (var i = 0; i<numEnt; i++)
            {
                if (r.space === 'local')
                {
                    //console.log('Local -> this.tweenData.rotation: ' + this.tweenData.rotation);
                    entToEffectNotNull[i].setLocalEulerAngles(this.tweenData.rotation);
                }
                else
                {
                    //console.log('World -> this.tweenData.rotation: ' + this.tweenData.rotation);
                    entToEffectNotNull[i].setEulerAngles(this.tweenData.rotation);  
                }
            }
        }.bind(this));

        // only set repeats if loopTween is false
        if (!r.loopTween)
            this.tweens.rotation.repeat(r.repeat);

        // start the tween
        this.tweens.rotation.start();
    }
};

EventResponse.prototype.perform_Tween_Scale = function(r) {
    
    if (this.tweens.scale) {
        this.tweens.scale.stop();
    }
    
    let entToEffectNotNull = r.entitiesToEffect.filter(e => e);
    let numEnt = entToEffectNotNull.length;
    if (numEnt > 0)        
    {
        //var to = (r.useTo === true) ? r.animateToVec3 : entToEffectNotNull[0].getLocalScale().clone();
        var to;// = (r.useTo === true) ? r.animateToVec3 : entPos;
        if (r.useTo) 
        {
            if (r.animateToEnt)
            {
                to = r.animateToEnt.getLocalScale().clone();
            }
            else
            {
                if (r.relativeScale)
                {
                    to = entToEffectNotNull[0].getLocalScale().clone().add(r.animateToVec3.clone());
                }
                else
                {
                    to = r.animateToVec3.clone(); // shallow copy
                }
            }
        }
        else
        {
            to = entToEffectNotNull[0].getLocalScale().clone();
        }
        if (r.useFrom) 
        {
            if (r.animateFromEnt)
            {
                entToEffectNotNull[0].setLocalScale(r.animateFromEnt.getLocalScale());
            }
            else
            {
                if (r.relativeScale)
                {
                    entToEffectNotNull[0].setLocalScale(entToEffectNotNull[0].getLocalScale().clone().add(r.animateFromVec3));
                }
                else
                {
                    entToEffectNotNull[0].setLocalScale(r.animateFromVec3);
                }
            }
            for (var i = 0; i<numEnt; i++)
            {
            entToEffectNotNull[i].setLocalScale(entToEffectNotNull[0].getLocalScale().clone()); 
            }
            
        }
        
        // create a new tween using our script attributes
        this.tweens.scale = this.app.tween(entToEffectNotNull[0].getLocalScale())
            .to(to, r.duration, pc[r.easing])
            .delay(r.delay)
            .loop(r.loopTween)
            .yoyo(r.yoyo);
        
        var updateFunction = (r.usePostUpdate) ? 'postUpdate' : 'update';
        this.tweens.scale.on(updateFunction, function () 
        {      
            for (var i = 0; i<numEnt; i++)
            {
                entToEffectNotNull[i].setLocalScale(entToEffectNotNull[0].getLocalScale().clone()); 
            }
        }.bind(this));

        // only set repeats if loopTween is false
        if (!r.loopTween)
            this.tweens.scale.repeat(r.repeat);

        // start the tween
        this.tweens.scale.start();
    }
};

EventResponse.prototype.perform_Rigidbody_Impulse = function(r) {
    const entToEffectNotNull = r.entitiesToEffect.filter(e => e && e.rigidbody);

    if (entToEffectNotNull.length > 0)
    {
        for (var i = 0; i < entToEffectNotNull.length; i++)
        {
            rigidbodyImpulse(r, entToEffectNotNull[i]);
        }
    }
    else if (this.entity.rigidbody)
    {
        rigidbodyImpulse(r, this.entity);
    }

    function rigidbodyImpulse(r, ent)
    {
        if(ent.rigidbody){
            ent.rigidbody.applyImpulse(r.impulseVector);
        }
    }
};

EventResponse.prototype.perform_Unload_Asset = function(r) {
    if (r.assetsToEffect.length > 0)
    {
        for (var i = 0; i < r.assetsToEffect.length; i++)
        {
            r.assetsToEffect[i].unload();
        }
    }
};
    


