// HandleSwipe.js
var HandleSwipe = pc.createScript('handleSwipe');

HandleSwipe.prototype.initialize = function() {
    this.app.on('ScrollParentPageDown', this.ScrollParentPageDown, this);
    this.app.on('ScrollParentPageUp', this.ScrollParentPageUp, this);
};

HandleSwipe.prototype.ScrollParentPageDown = function() {
    window.parent.scrollToMinus18();
};

HandleSwipe.prototype.ScrollParentPageUp = function() {
    window.parent.scrollToTop();
};


