// ZebrarMiddlewareForPC.js
 /*jshint esversion: 6 */
 let GameEvent = {
    //look in gamescreen.js to see how these get used.
    // events from game
    IFRAME_LOADED: "iframeLoaded",
    PROGRESS: 'progress',
    GAME_LOADED: "gameLoaded",
    GAME_FINISHED: "gameFinished",
    GAME_WON: "gameWon",
    GAME_LOST: "gameLost",
    GAME_RESTARTED: "gameRestarted",
    PRACTICE_STARTED: "practiceStarted",
    PRACTICE_FINISHED: "practiceFinished",

    // events from UI
    POST_SCORE_COMPLETE: "postScoreComplete",

    // instructions from game to UI
    REFRESH_GUI: "refreshGUI",
    SHOW_SCREEN: "showScreen",
    SHOW_MESSAGE: "showMessage",
    POST_SCORE: "postScore",
    STORE_LOCAL: "storeLocal",

    // instructions from UI to game
    RESTART: "restart"
 };
 
 var ZebrarMiddleware = class ZebrarMiddleware {
    static init() {
        //probably by setting ZebrarMiddleware.scene to the current scene main script
        //in that scene's init function you could call functions in the listener 
        //with something like ZebrarMiddleware.scene.doStuff()
        if (!ZebrarMiddleware.hasListener) {

            ZebrarMiddleware._loadingScene = false;

            window.addEventListener("gameEvent", ZebrarMiddleware.onGameEventReceived);
            ZebrarMiddleware.canClick = true;
            ZebrarMiddleware.currentScene = '';
            ZebrarMiddleware.hasListener = true;
            ZebrarMiddleware.tempCoords = new pc.Vec3();
        }
    }
    // static dispatch(EVENT_TYPE, detail = {}) {
    //     var message = { event: EVENT_TYPE };
    //     for (var i in detail) {
    //         message[i] = detail[i];
    //     }
    //     message = JSON.parse(JSON.stringify(message));
    //     var targetOrigin = window.location.origin;
    //     window.parent.postMessage(message, targetOrigin);
    // }
    static dispatch(EVENT_TYPE, detail = {}) {
        var message = { event: EVENT_TYPE };
        for (var i in detail) {
            message[i] = detail[i];
        }
        message = JSON.parse(JSON.stringify(message));
        var targetOrigin = '*';
        window.parent.postMessage(message, targetOrigin);
    }
    static onGameEventReceived(event) {
        if (event.origin !== window.location.hostname) return;
        switch (event.data.event) {
            case "startGrandHall":
                //start grand hall animation playing
                ZebrarMiddleware.scene.BeginGrandHall();
                break;
            case "exitToGrandHall":
                if (ZebrarMiddleware._loadingScene) {
                    //console.log("already ZebrarMiddleware._loadingScene  ignore startGrandHall event");
                    return;
                }

                ZebrarMiddleware._loadingScene = true;

                //Go back to grand hall scene here
                if (ZebrarMiddleware.currentScene == "grandHall") {
                    // Disabled back button in grandHall
                    // ZebrarMiddleware.scene.StepBack();
                } else {
                    ZebrarMiddleware.scene.ReturnToGrandHall();
                }
                break;
            case "bubbleClick":
                if (ZebrarMiddleware.currentScene != "grandHall") {
                    ZebrarMiddleware.scene.bubbleClick();
                }
                break;
            default:
                // console.log('unhandled event: ' + e.detail.event);
                break;
        }
    }

    static selectMeshByName(objectName) {
        // hightlight handle
        //var mi = ZebrarMiddleware.scene.model.model.meshInstances;
        //return mi.filter(i => i.node.name == objectName)[0];
        return ZebrarMiddleware.scene.app.root.findByName(objectName);
    }
    static getMeshScreenPosition(objectName) {
        var camInstance = ZebrarMiddleware.selectMeshByName('Camera');
        var meshInstance = ZebrarMiddleware.selectMeshByName(objectName);
        if (camInstance && camInstance.camera && meshInstance) {
            camInstance.camera.worldToScreen(meshInstance.getPosition(), ZebrarMiddleware.tempCoords);
            return ZebrarMiddleware.tempCoords;
        }
        return null;
    }
    static sendCoordinatesForObject(objectName) {
        if (ZebrarMiddleware.getMeshScreenPosition(objectName)) {
            ZebrarMiddleware.sendObjectCoordinates(objectName, ZebrarMiddleware.getMeshScreenPosition(objectName));
        }
    }

    static sendLoadProgress(progress) // LoadingScreen.js can't access ZebrarMiddleware.js, so this func is duplicated there
    {
        progress = Math.round(progress * 100);
        if (progress < 0) progress = 0;
        if (progress > 99) progress = 99;

        ZebrarMiddleware.dispatch(GameEvent.PROGRESS, { percentage: progress });
        // console.log(e.detail);
    }

    static sendGameLoaded() {
        ZebrarMiddleware.dispatch(GameEvent.GAME_LOADED, {});
        // console.log(e.detail);
    }

    /*
        VALID CATEGORIES: (Where categories are only used to differtiate between different games when a campagin has multiple games.)
        scoreGame1
        scoreGame2
        scoreGame3
        scoreGame4
        scoreGame5
        scoreGame6
        scoreGame7
        scoreGame8
        scoreOpenedApp
        scorePlayed6
    
        ex
        ZebrarMiddleware.sendGameScore('scoreGame1', gameManager.score);
    */
    static sendGameScore(category, score) {
        var message = { 
            event: 'postScore', 
            category: category,
            score: score 
        };
        message = JSON.parse(JSON.stringify(message));
        window.parent.postMessage(message, '*');
    }

    static sendGameFinished() {
        ZebrarMiddleware.dispatch(GameEvent.GAME_FINISHED, {});
        // console.log(e.detail);
    }

    //  GAME_WON: "gameWon",
    //  GAME_LOST: "gameLost",
    //  GAME_RESTARTED: "gameRestarted",
    //  PRACTICE_STARTED: "practiceStarted",
    //  PRACTICE_FINISHED: "practiceFinished",
    static sendGameWon() {
        ZebrarMiddleware.dispatch(GameEvent.GAME_WON, {});
    }

    static sendGameLost() {
        ZebrarMiddleware.dispatch(GameEvent.GAME_LOST, {});
    }

    static sendGameRestarted() {
        ZebrarMiddleware.dispatch(GameEvent.GAME_RESTARTED, {});
    }

    static sendPracticeStarted() {
        ZebrarMiddleware.dispatch(GameEvent.PRACTICE_STARTED, {});
    }

    static sendPracticeFinished() {
        ZebrarMiddleware.dispatch(GameEvent.PRACTICE_FINISHED, {});
    }

    static sendAnimationUpdate(animationName, animationTime) {
        ZebrarMiddleware.dispatch("animationUpdate", { animationName: animationName, animationTime: animationTime });
        // console.log(e.detail);
    }
    static sendObjectCoordinates(objectName, objectCoordinates) {
        ZebrarMiddleware.dispatch("objectCoordinates", { objectName: objectName, objectCoordinates: objectCoordinates });
    }
    static sendIdleClick() {
        ZebrarMiddleware.dispatch("idleClick", {});
    }
    static setCurrentScene(sceneName) {

        ZebrarMiddleware._loadingScene = false;

        ZebrarMiddleware.currentScene = sceneName;
        ZebrarMiddleware.dispatch("currentScene", { sceneName: sceneName });
        // console.log(e.detail);
    }
    static playSoundEffect(soundName) {
        ZebrarMiddleware.dispatch("playSoundEffect", { soundName: soundName });
        // console.log(e.detail);
    }

    static logTextures() {
        /*
            NOT USED (possible iphone 8 fix)
        */
        let loaded = [];
        for (var i = 0; i < pc.app.assets._assets.length; i++) {
            let asset = pc.app.assets._assets[i];
            if (asset.loaded && asset.type == "texture") {
                loaded.push(asset);
            }
        }
        //console.log(loaded.length);
        //console.log(loaded);
    }

    static unloadTextures() {
        /*
            NOT USED (possible iphone 8 fix)
        */
        for (var i = 0; i < pc.app.assets._assets.length; i++) {
            let asset = pc.app.assets._assets[i];
            if (asset.loaded && asset.type == "texture") {
                asset.unload();
            }
        }
    }

    static loadTextures() {
        for (var i = 0; i < pc.app.assets._assets.length; i++) {
            let asset = pc.app.assets._assets[i];
            if (asset == null) {
                //console.log("null asset");
                continue;
            }
            if (!asset.loaded && !asset.loadeding && asset.type == "texture") {
                asset.ready = function(a) {
                    //console.log("ready " + a.name);  
                };

                //console.log("load " + asset.name);
                pc.app.assets.load(asset);
            }
        }
    }

    static loadSceneTexturesAsync(rootEntity, onFinished) {
        /*
            NOT USED (possible iphone 8 fix)
            NOT FINISHED
        */
        let asyncLoad = {};
        asyncLoad.rootEntity = rootEntity;
        asyncLoad.onFinished = onFinished;
    }

    static findTexturesAsync(textures, nextEntity) {
        /*
            NOT USED (possible iphone 8 fix)
            NOT FINISHED
        */
    }

 };

window.ZebrarMiddleware = ZebrarMiddleware;

var ZBuilderTagging = pc.createScript('zBuilderTagging');
ZBuilderTagging.attributes.add('TaggedEntityProperties', {type: 'string', array: true});

