// SelfieManager.js
var SelfieManager = pc.createScript('selfieManager');

SelfieManager.attributes.add('entitiesToHide', {
    type: 'entity',
    array: true,
    description: 'Entities to hide.'
});

// initialize code called once per entity
SelfieManager.prototype.initialize = function() {
    
    this.app.on('captureSelfie', this.CaptureSelfie, this);

};

SelfieManager.prototype.CaptureSelfie = function() {
    this.app.fire('ui:takeScreenshot');
    //Hide Objects
    if (this.entitiesToHide.length>0)
    {
        for (var i = 0; i<this.entitiesToHide.length; i++)
        {
            this.entitiesToHide[i].enabled = false;
        }
    }
};

// swap method called for script hot-reloading
// inherit your script state here
// SelfieManager.prototype.swap = function(old) { };

// to learn more about script anatomy, please read:
// https://developer.playcanvas.com/en/user-manual/scripting/

