// CameraTexture.js
var CameraTexture = pc.createScript('cameraTexture');

CameraTexture.attributes.add('playEvent', {
    title: 'Play Event',
    description: 'Event that is fired as soon as the cam texture is ready to play.',
    type: 'string',
    default: ''
});

CameraTexture.attributes.add('camMaterial', {
    title: 'Camera Material',
    description: 'The material displays the camera texture.',
    type: 'asset',
    assetType: 'material'
});

CameraTexture.prototype.clearOldCamera = function(){
    // const video = document.querySelector('video');
    const video = this.cam;
    if (video)
    {
        // console.log("stopping media of video div", video);
        // A video's MediaStream object is available through its srcObject attribute
        const mediaStream = video.srcObject;

        if (mediaStream)
        {
            // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
            const tracks = mediaStream.getTracks();     

            if (tracks)
            {
                tracks.forEach(track => track.stop());
            }
        }
        video.remove();
    }
};

CameraTexture.prototype.initializeCamera = function(){
     //console.log("Init Camera", this.entity.name);

    if(this.cam != null)
    {
        this.clearOldCamera();
    }

    var app = this.app;
    
    // Create HTML Video Element to play the cam
    var cam = document.createElement('video');
    cam.autoplay = true;
    cam.crossOrigin = 'anonymous';
    cam.loop = true;
    cam.id = "CameraTexture-" + this.entity.name;

    // muted attribute is required for videos to autoplay
    cam.muted = true;

    // critical for iOS or the cam won't initially play, and will go fullscreen when playing
    cam.playsInline = true;
    
    if (navigator.mediaDevices.getUserMedia) {

        var camera;
        if (this.camMode == 0){
            camera = 'user'; 
        }
        else
        {
            camera = 'environment';
        }

        let constraints = { video: {facingMode: {exact: camera}} };
        this.constraints = constraints;

        this.cam = cam;
        this.camera = camera;

        navigator.mediaDevices.getUserMedia(constraints)
                                .then(this.getMediaSuccess.bind(this))
                                .catch(this.getMediaError.bind(this));
    } 

    cam.addEventListener( "loadedmetadata", function (e) {
    var width = this.videoWidth,
        height = this.videoHeight;
        // console.log(width+" : "+height);
    }, false );

    this.cam = cam;
};

CameraTexture.prototype.getMediaError = function(error) {
    // console.log("Something went wrong! " + error);

    if(window.location.href.includes("showcameraerrors=true"))
    {
        alert("camera permission issue:\n" + error.toString());
    }

    if(error != null 
        && (error.name == "OverconstrainedError" || error.name == "ConstraintNotSatisfiedError" ||  error.constraint != null))
    {
        if(window.location.href.includes("showcameraerrors=true"))
        {
            alert("retrying");
        }
        // camera doesn't exist

        // do a more flexible camera request
        var isIdeal = (this.constraints != null && this.constraints.facingMode != null && this.constraints.facingMode.ideal != null);
        if(!isIdeal)
        {
            var camera = this.camera;

            // use ideal
            let constraints = { video: {facingMode: {ideal: camera}} };
            this.constraints = constraints;

            navigator.mediaDevices.getUserMedia(constraints)
                                    .then(this.getMediaSuccess.bind(this))
                                    .catch(this.getMediaError.bind(this));
        }
    }
};

CameraTexture.prototype.getMediaSuccess = function(stream) {
    
    var app = this.app;

    var cam = this.cam;
    // set cam source
    cam.srcObject = stream;
    
    // iOS cam texture playback requires that you add the cam to the DOMParser
    // with at least 1x1 as the cam's dimensions
    var style = cam.style;
    style.width = '1px';
    style.height = '1px';
    style.position = 'absolute';
    style.opacity = '0';
    style.zIndex = '-1000';
    style.pointerEvents = 'none';

    document.body.appendChild(cam);

    // Create a texture to hold the cam frame data            
    this.camTexture = new pc.Texture(app.graphicsDevice, {
        format: pc.PIXELFORMAT_R8_G8_B8,
        addressU: pc.ADDRESS_CLAMP_TO_EDGE,
        addressV: pc.ADDRESS_CLAMP_TO_EDGE,
        mipmaps: false,
        autoMipmap: false
    });
    this.camTexture.setSource(cam);   
};

// initialize code called once per entity
CameraTexture.prototype.initialize = function() {
    this.camMode = 0;
    this.app.on('InitCam', this.initializeCamera , this);
    this.app.on('UserCam', function() {
        this.camMode = 0;
    }.bind(this));
    this.app.on('WorldCam', function() {
        this.camMode = 1;
    }.bind(this));
    this.app.on('SwapCam', function() {
        this.camMode = 1 - this.camMode;
    }.bind(this));
};


// update code called every frame
CameraTexture.prototype.update = function(dt) {
    // Transfer the latest cam frame to the cam texture
    if (this.camTexture && this.camMaterial){
        this.camTexture.upload();  
        var material = this.camMaterial.resource;
        material.emissiveMap = this.camTexture;
        material.update(); 
    }
};


