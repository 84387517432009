// Gyro.js
// This script relies on the device.js Device class

/*
Initialize:
    if iOS 
        Show iOSRequestGyroButton

Update:
    if validGryoData
        Updates self local rotation

// only on iOS
iOSGyroRequested:
    requestGyro
*/

var Gyro = pc.createScript('gyro');

Gyro.attributes.add('iOSRequestGyroButton', {type: 'entity'});
Gyro.attributes.add('iOS_Hide_Button_Not_iOS', {type: 'boolean', default: true});

// initialize code called once per entity
Gyro.prototype.initialize = function() 
{
    Gyro.instance = this;
    
    this.startingRotation = new pc.Quat().setFromEulerAngles(0, 0, 0);
    
    // Set as default "Portrait" Orientation
    var rotation = Gyro.deviceToQuat(this.startingRotation, { alpha: 0, beta: 90, gamma: 0 }, );
    this.entity.setLocalRotation(rotation);
    
    if (this.isIOS())
    {
        if (this.iOSRequestGyroButton) 
        {
            this.iOSRequestGyroButton.enabled = true;
            if (this.iOSRequestGyroButton)
            {
                if (this.iOSRequestGyroButton.button)
                {
                    this.iOSRequestGyroButton.button.on('click', () => 
                    {
                        Device.requestGyro();
                        Device.requestAccelerometer();
                    });
                }
            }
        }
    }
    else
    {
        if (this.iOSRequestGyroButton) 
        {
            if (this.iOS_Hide_Button_Not_iOS)
            {
                this.iOSRequestGyroButton.enabled = false;
            }
        }
        Device.requestGyro();
        Device.requestAccelerometer();
        this.AndroidInitialized = false; // is set true after first reading
    }
    this.accelCooldown = 0;
};

// update code called every frame
Gyro.prototype.update = function(dt) {
    
    this.elapsed += dt;
    
    if (Device.deviceorientation)
    {
        if (Device.deviceorientation.alpha !== null)
        {
            // set initial rotation on Android
            if (pc.platform.android && !this.AndroidInitialized)
            {
                if (Device.deviceorientation.alpha !== null)
                {
                    this.AndroidInitialized = true;
                    this.startingRotation = new pc.Quat().setFromEulerAngles(0, -Device.deviceorientation.alpha, 0);
                }
            }
            let device = Device.deviceorientation;
            let screen = {};
            var rotation = Gyro.deviceToQuat(this.startingRotation, Device.deviceorientation, Device.screenOrientation);
            
            // if(rotation.getEulerAngles().x < 0 && rotation.getEulerAngles().z< 0){
            //     rotation.setFromEulerAngles(0,rotation.getEulerAngles().y,0);
            // }

            // if(rotation.getEulerAngles().x > 90 && rotation.getEulerAngles().z > 90){
            //     rotation.setFromEulerAngles(0,rotation.getEulerAngles().y,0);
            // }

            // if(rotation.getEulerAngles().x < 0 && rotation.getEulerAngles().z > 90){
            //     rotation.setFromEulerAngles(0,rotation.getEulerAngles().y,0);
            // }

            // if(rotation.getEulerAngles().x > 90 && rotation.getEulerAngles().z < 0){
            //     rotation.setFromEulerAngles(0,rotation.getEulerAngles().y,0);
            // }
            //console.log(rotation.getEulerAngles());
            this.entity.setLocalRotation(rotation);
        }
    }

    if (Device.accelerometer && Device.devicemotion)
    {
        // if (Math.abs(Device.devicemotion.acceleration.x) > 1)
        //     this.DebugTextX.element.text = Device.devicemotion.acceleration.x.toFixed(3);
        if (Device.devicemotion.acceleration.y > 10.5)
        {
            if (this.accelCooldown <= 0.0)
            {
                this.app.fire('accel-input');
                this.accelCooldown = 0.125;
            }
        }

        if (this.accelCooldown > 0.0)
        {
            this.accelCooldown -= dt;
        }
    }
};

Gyro.prototype.performGetGyro = function()
{
    if (this.isIOS())
    {
        Device.requestGyro();
        Device.requestAccelerometer();
    }
};

Gyro.prototype.isIOS = function() 
{
    return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
};


Gyro.deviceToQuat = function(startingRotation, deviceorientation, screenOrientation)
{
    var alphaOffset =   deviceorientation.alphaOffset   ? deviceorientation.alphaOffset         : 0;
    var alpha =         deviceorientation.alpha         ? deviceorientation.alpha + alphaOffset : 0;
    var beta =          deviceorientation.beta          ? deviceorientation.beta                : 0;
    var gamma =         deviceorientation.gamma         ? deviceorientation.gamma               : 0;
    var orientation =   screenOrientation               ? screenOrientation                     : 0;
    
    var rot = startingRotation.clone();
    
    var deviceRot = Gyro.threeJsEulerToQuat(beta * Math.PI / 180, alpha * Math.PI / 180, -gamma * Math.PI / 180, "YXZ");
    rot = rot.mul(deviceRot);
    
    // - PI/2 around the x-axis
    var q1 = new pc.Quat( - Math.sqrt( 0.5 ), 0, 0, Math.sqrt( 0.5 ) );
    rot = rot.mul(q1);
    
    var zee = new pc.Vec3( 0, 0, 1 );
    rot = rot.mul( new pc.Quat().setFromAxisAngle(zee, - orientation ) );
    
    return rot;
};

Gyro.threeJsEulerToQuat = function(x, y, z, order)
{
    // THREE.Quaternion.setFromEuler
    // https://github.com/mrdoob/three.js/blob/master/src/math/Quaternion.js
    
    // http://www.mathworks.com/matlabcentral/fileexchange/
    // 	20696-function-to-convert-between-dcm-euler-angles-quaternions-and-euler-vectors/
    //	content/SpinCalc.m

    const cos = Math.cos;
    const sin = Math.sin;

    const c1 = cos( x / 2 );
    const c2 = cos( y / 2 );
    const c3 = cos( z / 2 );

    const s1 = sin( x / 2 );
    const s2 = sin( y / 2 );
    const s3 = sin( z / 2 );

     var q = {};
     switch ( order ) {

        case 'XYZ':
            q._x = s1 * c2 * c3 + c1 * s2 * s3;
            q._y = c1 * s2 * c3 - s1 * c2 * s3;
            q._z = c1 * c2 * s3 + s1 * s2 * c3;
            q._w = c1 * c2 * c3 - s1 * s2 * s3;
            break;

        case 'YXZ':
            q._x = s1 * c2 * c3 + c1 * s2 * s3;
            q._y = c1 * s2 * c3 - s1 * c2 * s3;
            q._z = c1 * c2 * s3 - s1 * s2 * c3;
            q._w = c1 * c2 * c3 + s1 * s2 * s3;
            break;

        case 'ZXY':
            q._x = s1 * c2 * c3 - c1 * s2 * s3;
            q._y = c1 * s2 * c3 + s1 * c2 * s3;
            q._z = c1 * c2 * s3 + s1 * s2 * c3;
            q._w = c1 * c2 * c3 - s1 * s2 * s3;
            break;

        case 'ZYX':
            q._x = s1 * c2 * c3 - c1 * s2 * s3;
            q._y = c1 * s2 * c3 + s1 * c2 * s3;
            q._z = c1 * c2 * s3 - s1 * s2 * c3;
            q._w = c1 * c2 * c3 + s1 * s2 * s3;
            break;

        case 'YZX':
            q._x = s1 * c2 * c3 + c1 * s2 * s3;
            q._y = c1 * s2 * c3 + s1 * c2 * s3;
            q._z = c1 * c2 * s3 - s1 * s2 * c3;
            q._w = c1 * c2 * c3 - s1 * s2 * s3;
            break;

        case 'XZY':
            q._x = s1 * c2 * c3 - c1 * s2 * s3;
            q._y = c1 * s2 * c3 - s1 * c2 * s3;
            q._z = c1 * c2 * s3 + s1 * s2 * c3;
            q._w = c1 * c2 * c3 + s1 * s2 * s3;
            break;

        default:
            console.warn( 'THREE.Quaternion: .setFromEuler() encountered an unknown order: ' + order );

    }
    
    return new pc.Quat(q._x, q._y, q._z, q._w);
};



